
$min_width: 320px;
$max_width: 1500px;
$min_font: 25px;
$max_font: 27px;


body.acc-switcher-opened {
  overflow: hidden;
}
.account-switcher-component-wrapper {
  position: relative;
  height: 45px;
  & > div {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.account-switcher-container {
    z-index: 12;
    position: fixed;
    top: 0px;
    right: 0px;
    transition: transform 0.3s ease-out 0s;
    will-change: transform;
    transform: translateX(102%);
    height: 100vh;
    width: 100%;
    @include breakpoint(medium) {
        width: 50%;
        padding-left: 6px;
    }
    @include breakpoint(large) {
        width: 28%;
    }
    .acc-switcher-opened & {
        transform: translateX(0);
    }
}

.account-switcher {
    height: 100%;
    overflow-y: scroll;
    @include breakpoint(small only) {
        padding-bottom: 8rem;
    }
}

.account-switcher-header {
    @include breakpoint(small only) {
        padding-top: 20px;
    }
    .close-icon {
        float: right;
        fill: $medium-grey;
        height: 1rem;
        margin-top: 0.3rem;
        cursor: pointer;
        padding-right: 0.3rem;
    }
}

.account-switcher-section {
    margin-bottom: 0.8rem;
    & > .cell {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
    .avatar-initials {
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
    .section-header {
        font-size: 1rem;
    }
}

.account-switcher-overlay {
    z-index: 11;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    transition: opacity 0.3s ease-out 0s, visibility 0.3s ease-out 0s;
    background-color: rgba(255, 255, 255, .6);
    opacity: 0;
    visibility: hidden;
    .acc-switcher-opened & {
        opacity: 1;
        visibility: visible;
        cursor: pointer;

    }
}

.account-switcher-trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: $bp*2;
  svg {
    @include screen-xs {
      display: none;
    }
  }
}

.account-switcher-entity-name {
  @include typography('account-1');
  margin-right: $bp*2;
  white-space: nowrap;
  @include screen-xs {
    display: none;
  }
}

.stacked-table {
  &:not(.big-table) {
    @include breakpoint(medium) {
      .row-flex {
        font-size: inherit;
        display: unset;
      }
      .card-base {
        box-shadow: unset;
        background: unset;
      }
      .button-inside-table {
        color: inherit;
        border: 0;
        width: unset;
        font-size: inherit;
        padding: 0;
        margin: 0;
        display: initial;
      }
    }

    @include breakpoint(small only) {
      @include table-stack($show-header-for-stacked);
      tr, th, td {
        border-bottom: 0;
        padding-top: 0;
      }
    }
  }

  &.big-table {
    @include breakpoint(medium down) {
      @include table-stack($show-header-for-stacked);
      tr, th, td {
        border-bottom: 0;
        padding-top: 0;
      }
    }
    @include breakpoint(large) {
      .row-flex {
        font-size: inherit;
        display: unset;
      }
      .card-base {
        box-shadow: unset;
        background: unset;
      }
    }
  }
}

table {
	width:100%;
	border-collapse: collapse;
	font-size: smaller;
	@include breakpoint(small only) {
		font-size: $font-size-smaller;
  }
	th {
		color: $primary-color;
		font-weight: 400;
	}
  th, td {
    border-bottom: 1px solid $light-grey;
    padding: $row-padding 0;
  }
	tr:hover {
		&.clickable-row {
		cursor: pointer;
		}
	}
}

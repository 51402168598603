.qr-reader {
  max-width: 320px;
  margin: 0 auto;
  section {
    z-index: -1;
  }
  section div {
    box-shadow: rgba($primary-color, 0.5) 0px 0px 0px 3px inset !important;
  }
  @media (max-width: 39.99rem) {
    margin-bottom: 2.5rem;
    section div {
      border-width: 30px !important;
    }
  }
}

.qr-code-img {
  @media print {
    height: 50mm !important;
    width: 50mm !important;
    left: 0;
    right: 0;
    position: relative;
    margin-top: 15mm;
  }
  display: block;
  margin: 1rem auto;
  &.small {
    margin: 0;
    height: 50px !important;
    width: 50px !important;
  }
  &.large {
    height: 150px !important;
    width: 150px !important;
  }
}

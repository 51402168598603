/* = = = Errors = = = */

.messages-list, .errorlist {
  margin: 0.5rem 0;
  clear: both;
  color: $dark-grey;
  li {
    border-left: solid 6px $alert-color;
    font-size: $font-size-smaller;
    font-weight: 300;
    text-align: left;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    &.warning, &.info{
      border-left: solid 6px $warning-color;
    }
    &.success{
      border-left: solid 6px $success-color;
    }
    &.flash-message, &.auto-hide {
      &.fixed {
        position: static;
        transform: none;
      }
      border: none;
      border-radius: 4px;
      position: fixed;
      background-color: #F67975ed;
      z-index: 2;
      text-align: center;
      left: 50%;
      transform: translate(-50%, 0);
      color: #420000;
      box-shadow: 0px 0px 3px 0px $white;
      padding: .5rem;
      &.warning, &.info{
        background-color: #FFEAA7ed;
        color: #845a00;
      }
      &.success {
        background-color: #bbf5bbed;
        color: #286D32;
      }
    }
    &.flash-message {
      transition: all 350ms ease-in-out;
      @include breakpoint(small only) {
        bottom: -80px;
        width: 100%;
        &.move-message {
          bottom: 80px;
        }
      }
      @include breakpoint(medium) {
        right: -100%;
        left: unset;
        &.move-message {
          right: -14%;
        }
      }
    }
  }
}

.error {
  border-left: solid 6px $alert-color;
  font-size: $font-size-smaller;
  font-weight: 300;
  text-align: left;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.2rem;
  form & {
    font-size: $input-error-font-size;
  }
}


.sentry-error-embed-wrapper .sentry-error-embed {
  form {
    margin: 0 auto;
  }
  p {
    margin: 0 0 10px;
  }
  .form-submit .btn {
    @extend .button;
    // @extend .primary;
    margin-top: 1rem;
    width: 100%;
  }
  .powered-by {
    display: none !important;
  }
  .form-submit .close {
    padding: 20px 0 10px;
    text-align: center;
    width: 100%;
    display: block;
    clear: both;
  }
}

/* = = = Lists & Rows = = = */

ol {
	list-style-type: none;
  	&.numbered {
    	list-style-type: decimal;
    	list-style-position: inside;
		}
		&.list-bullets {
			list-style-type: initial;
			margin-left: 1rem;
  	}
}

dl {
	width: 100%;
	overflow-y: scroll;
	font-size: $font-size-smaller;
}

dt {
	clear: left;
	padding-right: 0;
}

dd {
	text-align: right;
	padding-left: 0;
	margin-inline-start: 0;
	p {
		margin: 0;
	}
}

.numbered-list {
	counter-reset: numbered-list;
}

.div-list {
	margin: 0;
	line-height: 0;
	color: $medium-grey;
	font-size: 13px;
	padding-top: 0.3rem;
}


.row-flex {
	padding: $row-padding 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	font-size: $font-size-smaller;
	max-width: 87vw;
	&.full-width {
		padding-left: 0;
		padding-right: 0;
	}
	&.extra-padding-y {
		padding-top: $row-padding * 2;
		padding-bottom: $row-padding * 2;
	}
	&.multiline p {
		padding: 0 0 0 0.625rem;
		text-align: right;
	}
	input[type="checkbox"] {
		margin: 0;
	}
}

.row-item-extended {
	flex-grow: 2;
	overflow: hidden;
	.no-overflow {
		display: block;
	}
}

.multi-row-card {
	position: relative;
	padding: $row-padding 0;
	.subtitle {
		color: $medium-grey;
		font-weight: $font-weight-bold;
		font-size: $font-size-smaller;
		float: left;
		margin-top: 1.6rem;
		&.element-overlay {
			margin-top: 0;
			padding-top: 2rem;
		}
	}
	dd, dt {
		border-width: 0px;
		height: 1.2rem;
		float: left;
		margin: 0;
    	padding: 0;
	}
	dd {
		width: 45%;
	}
	dt {
		width: 55%;
	}
	dl {
		border-width: 0px;
		margin: 0;
		overflow-y: hidden;
	}
	.row-flex {
		border-bottom: 0;
		padding: 0;
	}
}

.card-base {
	border-radius: 10px;
	border-width: 0;
	// Shouldn't be transparent because of the current row actions
	background: $color-white-06;
	min-height: 4rem;
	position: relative;
	margin-bottom: 1rem;
	padding: $row-padding;
	line-height: inherit;
    // overflow: hidden;
	max-width: 100vw;
	&.disabled {
		background: $very-light-grey;
		color: $medium-grey;
	}
	&.card-disabled {
		background: $color-dirty-white;
		color: $medium-grey;
		cursor: not-allowed;
		pointer-events: none;
	}
	&.expanded {
		padding-bottom: 75px;
	}
	&.collapsed {
		padding-bottom: 30px;
		.hidden-content {
			@extend .hidden;
		}
		.overflow-limit {
			:not(:first-child){
				display: none;
			}
			:first-child {
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}
	p {
		padding: 0;
		margin: 0;
	}
	&.transition{
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition:all 0.2s ease-in-out;
		-o-transition:all 0.2s ease-in-out;
		transition:all 0.2s ease-in-out;
	}
	.absolute-item {
		display: block;
    position: absolute;
    height: 40px;
    width: 100%;
    left: -.5rem;
		text-align: center;
		margin-left: $row-padding;
		&.icon {
			padding-top: $row-padding;
			bottom: 0;
			height: 30px;
		}
	}
	.date {
		padding-right: 1em;
		color: $primary-color;
		font-weight: $font-weight-regular;
	}
	// .title {
	// 	font-weight: $font-weight-regular;
	// }
	.card-subtitle {
		padding-left: 5.5rem;
	}
}


/* = Sortable Lists = */

.sortable-drag {
  opacity: 1!important;
  &.row-flex {
    border-bottom: 1px solid $light-grey;
  }
//   .title::before {
//     content: "";
//   }
}
.sortable-chosen {
//   background: $white;
  opacity: 0.33;
}
.sortable-handle {
  cursor: move;
}
.absolute-handle {
  position:absolute;
  display: block;
  width: 1.5em;
  height: 100%;
  left:0;
  top:0;
  z-index: 9;
}

.bullet-list {
	ul {
		li::before {
			content: "\2022";
			color: #558ed5;
			font-weight: bold;
			display: inline-block;
			width: .8rem;
		}
	}
}

// Description Lists
.dl-with-accent {
  text-align: center;
  dd {
    color: $medium-grey;
    text-align: center;
  }
  dt {
    @extend h4;
    margin-bottom: 2rem;
  }
  &.right-align {
    @extend .align-text-right;
    dd {
      color: $medium-grey;
      text-align: right;
    }
    dt {
      font-size: medium;
      margin-bottom: 0;
    }
  }
}
.certificate-result {
  min-height: 100px;
  border-radius: 9px;
  box-shadow: 1px 1px 9px 1px rgba($primary-color, 0.1);
}

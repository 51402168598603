/* = = = User Messages = = = */

.user-message {
  @include breakpoint(small only) {
    font-size: $font-size-smaller;
  }
  // .title {
  //   font-weight: $font-weight-regular;
  // }
  .date {
    padding: 0;
  }
  &.detail .date {
    display: block;
    margin-bottom: $row-padding;
  }
  &.status-delivered, &.status-pending {
    .date:after {
      content: "● ";
      color: #558ed5;
      font-size: 1.35rem;
      line-height: 1rem;
      position: absolute;
      right: $row-padding;
    }
  }
}

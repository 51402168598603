@charset "utf-8";
@use "sass:map";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap");

/* = = = Settings = = = */

// Media Queries Settings
$screen-s-min: 600px;
$screen-m-min: 905px;
$screen-l-min: 1240px;
$screen-xl-min: 1440px;

$font-weight-normal: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 600;

$p-font-size: 14px;
$p-line-height: 20px;

// Color Settings
$color-primary-10: #23154C;
$color-primary-20: #351F72;
$color-primary-30: #462998;
$color-primary-40: #5834BE;
$color-primary-50: #8A71D2;
$color-primary-60: #AB99DF;
$color-primary-70: #CDC2EC;
$color-primary-80: #EEEBF9;
$color-primary-90: #F2F2FB;
$color-primary: $color-primary-40;

$color-secondary-10: #003064;
$color-secondary-20: #00468D;
$color-secondary-30: #005DB7;
$color-secondary-40: #2177D9;
$color-secondary-50: #4691F5;
$color-secondary-60: #75ACFF;
$color-secondary-70: #A7C8FF;
$color-secondary-80: #D4E3FF;
$color-secondary-90: #EBF1FF;

$color-neutral-10: #1E1E1F;
$color-neutral-20: #3C3C3E;
$color-neutral-30: #5C5C5F;
$color-neutral-40: #7B7B7E;
$color-neutral-50: #99999D;
$color-neutral-60: #B8B8BD;
$color-neutral-70: #D6D6DC;
$color-neutral-80: #E6E6EC;
$color-neutral-90: #F3F3F7;

$color-green-50: #00BE91;

$color-white-100: #FFF;
$color-white-06: #FBFBFB; // not part of the original style guide but need it for non transparent backgrounds, should be removed
$color-white-transparent: rgba($color-white-100, 0.6);

$color-background-10: #EFEEF0;
$color-background-20: #F4F4F5;

$color-data-purple: #5834BE;
$color-data-blue: #006BCD;
$color-data-light-blue: #35C7F5;
$color-data-green: $color-green-50;
$color-data-yellow: #EECA0D;

$color-alert: #D11F25;
$color-success: $color-green-50;

$color-text-default: $color-neutral-10;

// Radius settings
$border-radius-50: 50px;

// Info text settings
$info-text-color: $color-neutral-40;

// Mobile settings
$android-safe-area-top: 20px;

// Layout Settings
$bp: 4px;
$grid-template-areas:
  "navigation header"
  "navigation main";
$grid-template-areas-xs:
  "header"
  "main";
// @todo $app-content-padding-h by screen size, including page title animation
$app-content-padding-h-xs: $bp*6; // originally was $bp*4
$app-content-padding-h-s-m: $bp*6; // originally was $bp*8
$app-content-padding-h-gt-m: $bp*6;  // originally was $bp*10
$app-border-width: 1px;
$app-border-color: $color-neutral-80;

// Body Settings
$body-bg-color: $color-background-20;

// Menu Settings
// @todo introduce mapping with dedicated z indexes
$app-navigation-z-index: 15;
$menu-expanded-width: $bp*60;
$menu-collapsed-width: $bp*18;

// Tooltip settings
$tooltip-text-color: $color-text-default;
$tooltip-trigger-padding: $bp*2;
$tooltip-trigger-hover-bg: $color-primary-80;
$tooltip-trigger-hover-border-radius: $border-radius-50;
$tooltip-trigger-cursor: pointer;

// Tabs settings
$tab-border-radius: $bp*10;
$tab-text-color: $color-text-default;
$tab-text-active-color: $color-primary-40;
$tab-active-bg: $color-primary-80;
$tab-hover-bg: $color-primary-90;
$tab-group-border-bottom: 1px solid $color-neutral-80;
$tab-group-padding-bottom: $bp*5;

// Documents settings_form (not new design)
$document-row-bg: $color-white-transparent;
$document-row-border-radius: $bp*3;
$margin-bottom: $bp*4;
$document-row-min-height: $bp*16;


// Badge Settings
$badge-bg: $color-alert;
$badge-padding: 0 $bp;
$badge-size: $bp*4;
$badge-text-color: $color-white-100;
$badge-border-radius: $badge-size;
$badge-absolute-top: $bp*2;
$badge-absolute-left: 9.5vw;
// Badge Dot Settings
$badge-dot-bg: $badge-bg;
$badge-dot-size: 15px;
$badge-dot-border-width: 3px;
$badge-dot-border-color: $body-bg-color;
$badge-dot-top: -1*calc($bp/2);
$badge-dot-right: -1*$bp;
$badge-dot-z-index: 10;

// Links Settings
$link-text-color: $color-primary;
$link-hover-text-color: $color-primary-20;
$link-focus-text-color: $link-hover-text-color;
$link-focus-outline-color: $color-primary-50;
$link-focus-outline-width: 2px;
$link-focus-outline-radius: 4px;
$link-disabled-cursor: default;
$link-disabled-text-color: $color-neutral-60;

// Buttons Settings
// @todo consider composing properties with Mixins
$button-bg: $color-primary;
$button-border-radius: $border-radius-50;
$button-border-width: 1px;
$button-text-color: $color-white-100;
$button-icon-color: $button-text-color;
$button-padding: $bp*3 $bp*8;
$button-icon-padding: $bp*3;
$button-margin: $bp*2;
$button-cursor: pointer;
$button-max-width: 320px;
$button-hover-bg: $color-primary-30;
$button-focus-bg: $color-primary-20;
$button-focus-border-color: $color-primary-50;
$button-focus-outline-width: 1px;
$button-disabled-bg: $color-neutral-70;
$button-disabled-cursor: default;
$button-width-lt-m: 100%;
// Button Sizes Settings
$button-small-padding: $bp*2 $bp*4;
$button-small-width: fit-content;
$button-large-padding: $bp*7 $bp*8;
// Outlined Button Settings
$button-outlined-bg: transparent;
$button-outlined-text-color: $color-primary;
$button-outlined-icon-color: $button-outlined-text-color;
$button-outlined-border-color: $color-primary-60;
$button-outlined-hover-text-color: $color-primary-20;
$button-outlined-hover-icon-color: $button-outlined-hover-text-color;
$button-outlined-hover-border-color: $color-primary-20;
$button-outlined-focus-text-color: $color-primary-20;
$button-outlined-focus-border-color: $color-primary;
$button-outlined-focus-icon-color: $button-outlined-focus-border-color;
$button-outlined-disabled-text-color: $color-neutral-60;
$button-outlined-disabled-icon-color: $button-outlined-disabled-text-color;
$button-outlined-disabled-border-color: $color-neutral-70;
// Borderless Button Settings
$button-borderless-text-color: $color-primary;
$button-borderless-icon-color: $button-borderless-text-color;
$button-borderless-hover-bg: $color-primary-80;
$button-borderless-focus-bg: $button-borderless-hover-bg;
$button-borderless-disabled-text-color: $color-neutral-60;
$button-borderless-icon-color: $button-borderless-text-color;
$button-borderless-disabled-icon-color: $button-borderless-disabled-text-color;
// Button white
$button-inverted-bg: $color-white-100;

// Back Button Settings
// @todo consider using borderless button
$back-button-icon-size: $bp*4;
$back-button-padding: $bp*2;
$back-button-line-height: $back-button-icon-size;

// Header Settings
$header-height: $bp*16;
$header-height-net-xs: $bp * 18;
$header-height-xs: calc($header-height-net-xs + env(safe-area-inset-top));
$header-height-android: calc($header-height-net-xs + $android-safe-area-top);
$app-header-border-width: $app-border-width;
$app-header-border-color: $app-border-color;
$app-header-z-index: 9;

// Account Switcher Settings
$account-switcher-bg: $color-background-20;
$account-switcher-padding: $bp*4;
// $account-switcher-padding-top-small: $iphone-status-bar-height;
$account-switcher-link-color: $color-text-default;
$account-switcher-avatar-size: $bp*10;
$account-switcher-name-margin: 0 $bp*4 0 0;
$account-switcher-border-width: $app-border-width;
$account-switcher-border-color: $app-border-color;

// Page Title settings
$page-title-margin: $bp*2 0 $bp*4 0;
$page-title-z-index: 10;
$page-title-top-net-xs: $bp * 2;
$page-title-top-xs: calc($page-title-top-net-xs + env(safe-area-inset-top));
$page-title-top-android: calc($page-title-top-net-xs + $android-safe-area-top);
$page-subtitle-color: $color-neutral-30;

// Content Settings
$content-padding-bottom: $bp*20;
$content-padding-bottom-xs: calc($bp*40 + env(safe-area-inset-bottom));
$paragraph-margin: $bp*4 0;
$content-grid-column-gap: $bp*6;
$content-grid-row-gap: $bp*6;
$page-content-inserted-z-index: 10;

// Search Field Settings
$search-field-border-width: $app-border-width;
$search-field-border-color: $color-neutral-60;
$search-field-focus-border-color: $color-primary;
$search-field-border-radius: $bp*2;
$search-field-bg-color: $color-white-100;
$search-field-text-color: $color-neutral-30;
$search-field-text-padding-left: $bp*11;
$search-field-height: $bp*10;
$search-field-width: $bp*65;
$search-field-width-xs: 100%;
$search-field-margin: 0;

// Input Field Icons
$input-field-icon-padding: $bp*2 0 $bp*2 $bp*3;

// Effects Settings
$backdrop-color: $color-background-20;
$backdrop-opacity: 0.65;
$backdrop-fallback-opacity: 1;
$backdrop-blur: 12px;

// Loader Settings
$loader-size: 5em;
$loader-stroke-width: $loader-size*0.11;
$loader-color-1: $color-primary-70;
$loader-color-2: rgba($loader-color-1, 0.33);

// Main Content Area Settings
$main-content-area-bg: $color-white-transparent;
$main-content-area-border-radius: $bp*3;
$main-content-area-header-width: 100%;
$main-content-area-header-padding-top: $bp*9;
$main-content-area-header-padding-bottom: $bp*4;
$main-content-area-header-padding-left: $bp*10;
$main-content-area-header-padding-top-xs: $bp*6;
$main-content-area-header-padding-bottom-xs: $bp*5;
$main-content-area-header-padding-left-xs: $main-content-area-header-padding-bottom-xs;
$main-content-area-title-padding-bottom-xs: $main-content-area-header-padding-bottom-xs;

// Table Settings
$table-text-color: $color-neutral-20;
$table-text-color-secondary: $color-neutral-40;
$table-border-bottom: 1px solid $color-neutral-80;
$table-cell-padding-left: $bp*10;
$table-cell-padding-right: $bp*4;
$table-cell-padding-top: $bp*3;
$table-cell-padding-bottom: $table-cell-padding-top;
$table-cell-padding-xs: $bp;
$table-cell-button-margin: 0 $bp*2 0 0;
$table-row-padding-xs: $bp*4 $bp*5;

// Pagination Controller Settings
$pagination-controller-padding: $bp*6 $bp*10;
$pagination-controller-width: $bp*108;
$pagination-items-counter-padding: $bp;
$pagination-color: $color-neutral-20;

// Typography Settings
$typography: (
  //                 font-size
  //                 |    line-height
  //                 |    |      font-weight + color (optional)
  //                 |    |      |
  "number-1":        48px $bp*14 $font-weight-normal,
  "number-2":        36px $bp*10 $font-weight-normal,
  "number-3":        28px $bp*8  $font-weight-semi-bold,
  "number-4":        20px $bp*7  $font-weight-normal,
  "heading-1":       36px $bp*11 $font-weight-bold,
  "heading-2":       28px $bp*9  $font-weight-bold,
  "heading-3":       24px $bp*8  $font-weight-bold,
  "heading-4":       20px $bp*7  $font-weight-bold,
  "heading-5":       18px $bp*6  $font-weight-bold,
  "sub-heading-1":   12px $bp*4  $font-weight-normal,
  "title-1":         22px $bp*7  $font-weight-semi-bold,
  "title-2":         15px $bp*5  $font-weight-semi-bold,
  "body-1":          16px $bp*6  $font-weight-normal,
  "body-2":          14px $bp*5  $font-weight-normal $color-neutral-40,
  "body-3":          13px $bp*4  $font-weight-normal,
  "body-4":          10px $bp*3  $font-weight-normal,
  "label-1":         14px $bp*4  $font-weight-semi-bold,
  "label-2":         11px $bp*4  $font-weight-normal,
  "input-value-1":   15px $bp*5  $font-weight-bold $color-neutral-20,
  "button-1":        14px $bp*4  $font-weight-normal $button-text-color,
  "menu-1":          14px $bp*5  $font-weight-normal,
  "menu-2":          11px $bp*4  $font-weight-normal,
  "account-1":       14px $bp*5  $font-weight-bold,
  "badge-1":         10px $badge-size $font-weight-normal $badge-text-color,
) !default;
// @todo consider swapping the logic and using xs as default
$typography-xs: map.merge(
  $typography,
  (
    "number-1":  42px $bp*12 $font-weight-normal,
    "number-2":  32px $bp*10 $font-weight-normal,
    "number-3":  26px $bp*8  $font-weight-semi-bold,
    "number-4":  20px $bp*7  $font-weight-normal,
    "heading-1": 32px $bp*10 $font-weight-bold,
    "heading-2": 26px $bp*8  $font-weight-bold,
    "heading-3": 22px $bp*7  $font-weight-bold,
    "heading-4": 18px $bp*6  $font-weight-bold,
    "heading-5": 16px $bp*5  $font-weight-bold,
  )
);
// Generic Tiles Settings
// @todo consider merging colors here instead of alpha (performance)
$tile-bg-color: $color-white-transparent;
$tile-border-radius: $bp*3;
$tile-bg-color-disabled: $color-neutral-80;
$tile-grid-column-gap: $content-grid-column-gap;
$tile-grid-row-gap: $content-grid-row-gap;
$tile-grid-margin: $content-grid-row-gap 0;
$tile-padding: $bp*8;
$tile-padding-xs: $bp*5;
$tile-content-padding-bottom: $tile-padding;
$tile-padding-top: $bp*6;

// Datalist Tiles Settings
$datalist-labe-padding-top: $tile-padding;
$datalist-label-color: $color-neutral-30;
$datalist-value-color: $color-neutral-20;

// Widgets Settings
$widgets-grid-columns-xs: 1;
$widgets-grid-columns-gt-m: 2;
$widget-header-padding-bottom: $bp*4;
$widget-header-icon-padding: $bp*3;
// Widget content row setting
$widget-content-row-icon-margin: $bp*2;
$avatar-image-height: $bp*8.45;
$avatar-image-width: $avatar-image-height;

// Form Section Settings
$form-section-overview-data-row-gap: $bp*8;
$form-section-overview-data-column-gap: $bp*11;
$form-section-overview-data-label-color: $color-neutral-30;
$form-section-overview-data-label-padding: $bp*2;

// Pricing widgets
$pricing-tiles-grid-columns: auto-fit;
$pricing-tile-min-width: $bp*65.5;
$pricing-tile-min-height: $bp*157.5;
$pricing-tile-min-default-height: $bp*100;
$pricing-tile-button-width: calc(100% - $tile-padding * 2);
$pricing-tile-button-width-xs: calc(100% - $tile-padding-xs * 2);
$pricing-tile-button-bottom: $bp*8;
$pricing-tile-button-left: 0;
$pricing-tile-button-right: $pricing-tile-button-left;
$pricing-tile-label-padding-left: $bp*2;
$pricing-tile-label-padding-bottom: $bp*5;
$pricing-tile-label-padding-top: $pricing-tile-label-padding-bottom;
$pricing-tile-paid-item-padding-left: $bp*7;
$pricing-tile-section-border-bottom: 1px solid $color-neutral-80;
$pricing-tile-sub-header-color: $color-neutral-40;
$pricing-tile-content-padding: 0 $bp*8 $bp*8;
$pricing-tile-paid-item-color: $color-neutral-40;

// Rows Settings
$content-row-color: $color-neutral-10;
$content-row-columns: fit-content(100%) 8fr fit-content(100%);
$content-row-width: 100%;
$content-row-padding: $bp*4;
$content-row-text-color: $color-neutral-40;
$content-row-margin: 0;
$content-row-line-clamp: 1;

// Files Settings
$file-content-container-padding: $bp*8;
$file-content-container-preview-width: minmax(min-content, $bp*16);
$file-content-container-column-gap: $bp*6;
$file-info-color: $color-neutral-10;
$file-info-preview-width: 100%;
$file-info-icon-height: $bp*16;
$file-info-items-padding: $bp;
$file-info-type-color: $color-neutral-40;
$file-info-action-color: $color-primary;

// Tags Settings
$tag-border-radius: $bp*6;
$tag-bg-color: $color-neutral-80;
$tag-border: 1px solid $tag-bg-color;
$tag-outlined-bg-color: transparent;
$tag-outlined-border-color: $color-neutral-50;
$tag-color: $color-neutral-30;
$tag-margin: $bp*2 0 $bp;
$tag-padding: $bp $bp*2;
$tag-label-padding-left: $bp;
$tag-success-border-color: rgba($color-data-green, $backdrop-opacity);
$tag-success-bg-color: rgba($color-data-green, $backdrop-opacity);

// Modal Settings
$modal-padding: $bp*8;
$modal-content-padding: $modal-padding;

// Managed Account Settings
$account-banner-bg: $color-data-yellow;
$account-banner-sides-padding: $bp*10;
$account-banner-top-padding: 0;
$account-banner-bottom-padding: $bp*4;
$account-banner-top-padding-xs: calc($bp*2 + env(safe-area-inset-top));
$account-banner-top-padding-android: calc($bp*2 + $android-safe-area-top);
$account-banner-bottom-padding-xs: $account-banner-bottom-padding;
$account-banner-z-index: 10;
$account-banner-top: 0;
$account-banner-grid-columns: repeat(3, 1fr);
$account-text-column: 2;
$account-gap-xs: $bp*2;
$account-name-weight: $font-weight-bold;
$account-banner-height: $bp*16;
$account-banner-height-xs: calc($bp*21 + env(safe-area-inset-top));
$account-banner-height-android: calc($bp*21 + $android-safe-area-top);
// Header with Banner Settings
$header-with-banner-height: calc($header-height + $account-banner-height);
$header-with-banner-height-xs: calc($header-height-net-xs + $account-banner-height-xs);
$header-with-banner-height-android: calc($header-height-net-xs + $account-banner-height-android);
// Page Title with Banner Settings
$page-title-with-banner-top: $account-banner-height;
$page-title-with-banner-top-xs: calc($page-title-top-net-xs + $account-banner-height-xs);
$page-title-with-banner-top-android: calc($page-title-top-net-xs + $account-banner-height-android);

/* = = = Mixins = = = */

// Media Queries Mixins
$screen-xs-max: $screen-s-min - 1;
$screen-s-max: $screen-m-min - 1;
$screen-m-max: $screen-l-min - 1;
$screen-l-max: $screen-xl-min - 1;
@mixin screen-xs {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}
@mixin screen-s {
  @media screen and (min-width: #{$screen-s-min}) and (max-width: #{$screen-s-max}) {
    @content;
  }
}
@mixin screen-m {
  @media screen and (min-width: #{$screen-m-min}) and (max-width: #{$screen-m-max}) {
    @content;
  }
}
@mixin screen-l {
  @media screen and (min-width: #{$screen-l-min}) and (max-width: #{$screen-l-max}) {
    @content;
  }
}
@mixin screen-xl {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}
@mixin screen-s-m {
  @media screen and (min-width: #{$screen-s-min}) and (max-width: #{$screen-m-max}) {
    @content;
  }
}
@mixin screen-lt-m {
  @media screen and (max-width: #{$screen-s-max}) {
    @content;
  }
}
@mixin screen-lt-l {
  @media screen and (max-width: #{$screen-m-max}) {
    @content;
  }
}
@mixin screen-lt-xl {
  @media screen and (max-width: #{$screen-l-max}) {
    @content;
  }
}
@mixin screen-gt-xs {
  @media screen and (min-width: #{$screen-s-min}) {
    @content;
  }
}
@mixin screen-gt-s {
  @media screen and (min-width: #{$screen-m-min}) {
    @content;
  }
}
@mixin screen-gt-m {
  @media screen and (min-width: #{$screen-l-min}) {
    @content;
  }
}
@mixin on-focus {
    &:focus-visible {
        @content;
    }
}
@mixin on-hover {
  @media (hover: hover) and (pointer: fine) {
      &:hover {
          @content;
      }
  }
}

// Layout Mixins
@mixin app-content-padding-bottom {
  padding-bottom: $content-padding-bottom-xs;
  @include screen-gt-xs {
    padding-bottom: $content-padding-bottom;
  }
}

@mixin app-content-padding-h {
  padding-left: $app-content-padding-h-xs;
  padding-right: $app-content-padding-h-xs;
  @include screen-s-m {
    padding-left: $app-content-padding-h-s-m;
    padding-right: $app-content-padding-h-s-m;
  }
  @include screen-gt-m {
    padding-left: $app-content-padding-h-gt-m;
    padding-right: $app-content-padding-h-gt-m;
  }
}

// Typography Mixins
@mixin typography_rules(
  $font-size,
  $line-height,
  $font-weight,
  $color: $color-text-default
) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  // color: $color;
}
@mixin typography($name) {
  @include screen-xs {
    @include typography_rules(map.get($typography-xs, $name)...);
  }
  @include screen-gt-xs {
    @include typography_rules(map.get($typography, $name)...);
  }
}

// Widgets Mixins

@mixin widget-sides-padding {
  padding-left: $tile-padding;
  padding-right: $tile-padding;
  @include screen-xs {
    padding-left: $tile-padding-xs;
    padding-right: $tile-padding-xs;
  }
}

// Rows Mixins
@mixin content-row-grid {
  display: grid;
  grid-template-columns: $content-row-columns;
	width: $content-row-width;
}

@mixin content-row-title {
  @include typography('title-2');
	color: $content-row-color;
}

@mixin row-main-content {
  color: $content-row-text-color;
	@include typography('body-2');
	margin: $content-row-margin;
	display: -webkit-box;
	-webkit-line-clamp: $content-row-line-clamp;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-break: break-all;
}


// Effects Mixins
@mixin backdrop-blur {
  background: rgba($backdrop-color, $backdrop-fallback-opacity);
  @supports (backdrop-filter: none) {
    background: rgba($backdrop-color, $backdrop-opacity);
    backdrop-filter: blur($backdrop-blur);
  }
}
@mixin backdrop-blur-reset {
  background: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

// Tiles Mixins
@mixin tiles {
  grid-column-gap: $tile-grid-column-gap;
  column-gap: $tile-grid-column-gap;
  grid-row-gap: $tile-grid-row-gap;
  row-gap: $tile-grid-row-gap;
  margin: $tile-grid-margin;
  display: grid;
}

@mixin tiles-columns {
  grid-template-columns: repeat($widgets-grid-columns-xs, 1fr);

  @include screen-gt-m {
    grid-template-columns: repeat($widgets-grid-columns-gt-m, 1fr);
  }
}

@mixin tile {
  background: $tile-bg-color;
  border-radius: $tile-border-radius;
  &.disabled {
    background: $tile-bg-color-disabled;
  }
}

@mixin tile-header {
  padding: $tile-padding-top $tile-padding $widget-header-padding-bottom;

  @include screen-xs {
    padding: $tile-padding-top $tile-padding-xs $widget-header-padding-bottom;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-with-icon {
    display: flex;
  }

  .icon {
    padding-right: $widget-header-icon-padding;
    display: flex;
    align-items: center;
  }

  &.action {
    cursor: pointer;
  }
}

@mixin tile-content-padding {
  padding: 0 $tile-padding $tile-content-padding-bottom;

  @include screen-xs {
    padding: 0 $tile-padding-xs $tile-content-padding-bottom;
  }
}

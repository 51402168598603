/* = = = Forms = = = */

$input-field-background: $color-white-100;
$input-field-border: 1px solid $color-neutral-60;
$input-field-disabled-border-color: $color-neutral-70;
$input-field-disabled-background: transparent;
$input-field-disabled-color: $color-neutral-50;
$input-field-border-radius: $bp*1.5;
$input-field-height: $bp*12;
$input-field-padding-h: $bp*3;
$input-field-padding-v: 13px;
$input-field-margin-bottom: $bp*8;
$input-field-border-color-focused: $color-primary-50;
$input-field-outline-focused: 1px solid $input-field-border-color-focused;
$input-value-color: $color-neutral-20;
$input-field-border-color-hovered: $color-neutral-20;
// Checkbox
$checkbox-margin-right: $bp*3;
$checkbox-field-border: 1px solid $color-neutral-70;
$checkbox-checked-color: $color-primary;
$checkbox-disabled-color: $color-neutral-70;
$checkbox-hover-color: $color-primary;
$checkbox-checked-hover-color: $color-primary-30;
$checkbox-focus-outline: 2px solid $color-primary-50;
$checkbox-height: $bp*5;
$checkbox-width: $checkbox-height;
$checkbox-border-radius: $bp;
$checkbox-label-padding: 0;
$checkbox-checked-content: url('data:image/svg+xml; utf8, <svg width="12.5" height="9.75" viewBox="-4 -5 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.95123 7.40245L1.18333 4.63456L0 5.80956L3.95123 9.76079L12.537 1.175L11.362 0L3.95123 7.40245Z" fill="white"/></svg>');
// Radio
$radio-checked-content: url('data:image/svg+xml; utf8, <svg width="20" height="20" viewBox="1 1 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M11 5.918c1.357 0 2.634.53 3.594 1.49 1.98 1.98 1.98 5.204 0 7.185-.96.96-2.237 1.49-3.594 1.49s-2.634-.53-3.594-1.49c-1.98-1.98-1.98-5.205 0-7.186.96-.96 2.237-1.49 3.594-1.49M11 1c-2.56 0-5.12.976-7.07 2.93-3.906 3.904-3.906 10.236 0 14.14C5.88 20.024 8.44 21 11 21s5.12-.976 7.07-2.93c3.906-3.904 3.906-10.236 0-14.14C16.12 1.976 13.56 1 11 1z"/></svg>');
$radio-border-radius: 50%;
// Ratio fields
$ratio-input-field-width: $bp*20;
$ratio-input-separator-margin: 0 $bp*6 0;
$ratio-input-separator-color: $color-neutral-30;
// Labels
$input-label-color: $color-neutral-30;
$input-label-color-disabled: $color-neutral-50;
$input-label-font-weight: $font-weight-semi-bold;
$input-label-padding-bottom: $bp*2;
// Dropzone
$dropzone-color: $color-neutral-30;
$dropzone-max-height: $bp*151;
$dropzone-text-padding: $bp*4 0 0;
$dropzone-text-margin: 0;
// Form sections
$form-sections-content-padding: 0;
$form-sections-header-padding-bottom: $bp*8;
$form-sections-overview-padding-bottom: $bp*4;
// Document upload
$document-upload-width: 100%;
$document-upload-section-width: 50%;
$document-upload-section-width-lt-m: 100%;
$document-upload-section-padding-top: $bp*4;
$document-upload-section-padding-bottom: $bp*8;
$document-upload-section-padding-h: 0;
$document-upload-section-action-text-margin: 0;
$document-upload-section-action-icon-padding-right: $bp;
$document-upload-properties-padding-right: $bp*11;
$document-upload-properties-border: 1px solid $color-neutral-80;
$document-upload-properties-padding-bottom: $bp*6;
$document-upload-files-padding-left: $document-upload-properties-padding-right;
$document-upload-files-padding-left-lt-m: 0;
$document-upload-files-padding-top-lt-m: $document-upload-properties-padding-bottom;
// Form action
$form-action-padding-top-bottom: $bp*10;
$form-action-padding-sides: 0;
$form-action-margin-top-gt-s: $bp*28;
$form-action-border-top-gt-s: 1px solid $color-neutral-80;


// Consider removing
form, .form {
  p {
    padding: 0;
    margin: 0;
  }
}

.login, .signup {
  .text-link {
    text-align: center;
    font-size: $font-size-smaller;
    padding-top: 1rem;
    a {
      color: $dark-grey;
    }
  }
  p {
    padding: 0;
  }
}


// Fieldset
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}


// Inputs

input, textarea, select {
  width: 100%;
  font-family: Manrope, sans-serif;
}

[type='text'], [type='password'], [type='date'],
[type='datetime'], [type='datetime-local'],
[type='month'], [type='week'], [type='email'],
[type='number'], [type='search'], [type='tel'],
[type='time'], [type='url'], [type='color'], textarea, select, .react-tel-input {
  @include typography('input-value-1');
  color: $input-value-color;
  background: $input-field-background;
  border: $input-field-border;
  border-radius: $input-field-border-radius;
  padding: $input-field-padding-v $input-field-padding-h;
  // height: $input-field-height;
  margin-bottom: $input-field-margin-bottom;
  @include on-focus {
    border-color: $input-field-border-color-focused;
    outline: $input-field-outline-focused;
  }
  @include on-hover {
    border-color: $input-field-border-color-hovered;
  }
  &:disabled {
    color: $input-field-disabled-color;
    border-color: $input-field-disabled-border-color;
    background: $input-field-disabled-background;
  }
  &:focus-visible {
    outline: none;
  }
}
    // disable margin from webapp styles
    [class^="css-"] input {
      margin: inherit;
      padding: inherit;
      border-radius: inherit;
    }

label {
  @include typography('label-1');
  padding-bottom: $input-label-padding-bottom;
  color: $input-label-color;
  display: block;
}

textarea {
  @include typography('title-2');
  resize: none;
}

// Radio input
input[type="radio"] {
  margin-right: $checkbox-margin-right;
  margin-bottom: $input-field-margin-bottom;
}

// Checkbox input
.checkbox-input, .radio-input  {
  margin: 0 $checkbox-margin-right $input-field-margin-bottom 0;
  background: $input-field-background;
  border: $checkbox-field-border;
  min-width: $checkbox-width;
  max-width: $checkbox-width;
  max-height: $checkbox-height;
  min-height: $checkbox-height;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  &:checked {
    background: $checkbox-checked-color;
    border-color: $checkbox-checked-color;
    @include on-hover {
      background: $checkbox-checked-hover-color;
      border-color: $checkbox-checked-hover-color;
    }
  }
  &:disabled {
    &, &:checked {
      cursor: default;
      background: $checkbox-disabled-color;
      border-color: $checkbox-disabled-color;
    }
  }
  @include on-hover {
    border-color: $checkbox-hover-color;
  }
  @include on-focus {
    &, &:checked {
      outline: $checkbox-focus-outline;
    }
  }
}

.checkbox-input {
  border-radius: $checkbox-border-radius;
  &:checked {
    content: $checkbox-checked-content;
  }
}
.radio-input {
  border-radius: $radio-border-radius;
  &:checked {
    content: $radio-checked-content;
  }
}

.radio-checkbox-label {
  padding: $checkbox-label-padding;
  line-height: $checkbox-height;
}

.radio-checkbox-wrapper {
  display: flex;
}

.ratio-fields-group {
  display: flex;
  align-items: center;
  .ratio-input-field {
    width: $ratio-input-field-width;
  }
  .ratio-input-field-separator {
    margin: $ratio-input-separator-margin;
    color: $ratio-input-separator-color;
    @include typography('body-2')
  }
}

.dropzone {
  @include typography('body-2');
  color: $dropzone-color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: $dropzone-max-height;
  cursor: pointer;
  margin: 0 auto;
  .dropzone-text {
    padding: $dropzone-text-padding;
    margin: $dropzone-text-margin;
    text-align: center;
  }
  input[type="file"] {
    display: none;
  }
}

.form-sections {
  @include tiles;
  @include tiles-columns;
  >:first-child {
    grid-row: 1 / 3;
  }
  .section-overview-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: $form-section-overview-data-row-gap;
    column-gap: $form-section-overview-data-column-gap;
    padding-bottom: $form-sections-overview-padding-bottom;

    .label {
      @include typography('label-1');
      color: $form-section-overview-data-label-color;
      padding-bottom: $form-section-overview-data-label-padding;
    }

    .number {
      @include typography('number-3');
    }

    .currency {
      @include typography('number-4')
    }
  }
  .document-upload-form-section {
    @include screen-gt-m {
      grid-column: 1 / 3;
    }
  }
}
.form-section {
  @include tile;
}
.form-section-content {
  @include tile-content-padding;
  padding-bottom: $form-sections-content-padding;
}

.form-section-header {
  @include tile-header;
  padding-bottom: $form-sections-header-padding-bottom;
}

.document-upload-section {
  width: $document-upload-section-width;
  @include screen-lt-m {
    width: $document-upload-section-width-lt-m;
  }
  .section-header {
    padding: $document-upload-section-padding-top $document-upload-section-padding-h $document-upload-section-padding-bottom;
    display: flex;
    justify-content: space-between;
    .title {
      @include typography('title-2');
    }
    .action {
      @include typography('label-1');
      margin: $document-upload-section-action-text-margin;
      color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        padding-right: $document-upload-section-action-icon-padding-right;
      }
    }
  }
}

.document-upload-form {
  display: flex;
  width: $document-upload-width;
  padding-bottom: $document-upload-section-padding-bottom;
  @include screen-lt-m {
    flex-direction: column;
  }
  >:first-child {
    padding-right: $document-upload-properties-padding-right;
    border-right: $document-upload-properties-border;
    @include screen-lt-m {
      padding-right: 0;
      border-right: none;
      padding-bottom: $document-upload-properties-padding-bottom;
      border-bottom: $document-upload-properties-border;
    }
  }
  >:nth-child(2) {
    padding-left: $document-upload-files-padding-left;
    @include screen-lt-m {
      padding-left: $document-upload-files-padding-left-lt-m;
      padding-top: $document-upload-files-padding-top-lt-m;
    }
  }
}

.form-action {
  padding: $form-action-padding-top-bottom $form-action-padding-sides;
  @include screen-gt-s {
    border-top: $form-action-border-top-gt-s;
    margin-top: $form-action-margin-top-gt-s;
  }
}


// Old styles which are missing new designs
.radio-button-group-line {
  display: flex;
  justify-content: space-between;
  width: 65%;
}

.monetary-value-input {
  display: flex;
  justify-content: space-between;
  .currency {
    width: 35%;
  }
  .price-value {
    width: 60%;
  }
}

.form-step-navigation {
  display: flex;
  justify-content: space-evenly;
  width:101%;
  margin-left: -1%;
  margin-bottom: 1rem;
  li {
    width: 100%;
    a, span {
      margin: 0 2%;
      display: block;
      height: 8px;
      background-color: $light-grey;
      &.active {
        background-color: $primary-color;
      }
    }
  }
}

.card-radio {
  input[type="radio"], input[type='checkbox'] {
    display: none;
  }
  input[type="radio"] + label,
  input[type="checkbox"] + label {
    display: flex;
    margin: 0 0 1rem 0;
    &.small-items {
      display: inline-block;
      min-height: fit-content;
      width: fit-content;
    }
  }
  input[type="radio"]:checked + .card-base,
  input[type='checkbox']:checked + .card-base {
    background: $primary-color;
    color: $white;
  }
}

.react-tel-input {
  padding: 0;
  border-radius: $input-field-border-radius;
  .flag-dropdown {
    border-radius: $input-field-border-radius;
    border: 0;
    background: white;
    &.open {
      border-radius: $input-field-border-radius;
      .country-list {
        top: 48px;
        background-color: white;
        border-radius: 2px;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
        z-index: 1;
        min-width: 258px;
        margin: 0;
        font-family: Manrope, sans-serif;
        .country {
          padding: 8px 12px 8px 44px;
          &.highlight {
            background-color: #5834BE;
            color: white;
            .dial-code {
              color: white;
            }
          }
          &:hover {
            background-color: #EEEBF9;
          }
        }
      }
    }
    .selected-flag {
      border-radius: $input-field-border-radius;
      &.open {
        border-radius: $input-field-border-radius;
      }
    }
  }
  .form-control {
    height: 100%;
    @include typography('input-value-1');
    width: 100%;
    border: none;
    border-radius: $input-field-border-radius;
    color: $input-value-color;
    letter-spacing: normal;
    padding: 14px 12px 14px 60px;
  }
}

/* = Switch = */

.switch-paddle {
    &, &::after {
        @include border($grey);
    }
}
input:checked ~ .switch-paddle{
    &, &::after {
        @include border($primary-color);
    }
}
.switch-input:disabled {
  opacity: 0;
}

.switch.tiny .switch-paddle::after {
    top: -0.05rem;
    width: 1.35rem;
    height: 1.35rem;
}

.datetime {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  & > * {
    width : 100%;
  }
}

.datetime-date[type='date'] {
  width: 100%;
  margin-right: 0;
}
.datetime-time[type='time'] {
  width: 90%;
  margin-left: 10%;
}

.filter-form {
  padding: 0;
  margin: 0;
  input[type="date"] {
    margin: 0;
    padding: rem-calc(7);
    text-align: center;
    color: $white;
    width: auto;
    height: 1.5rem;
    border: none;
    border-radius: 50px;
    background-color: $primary-color;
    font-size: 0.75rem;
    display: inline-flex;
  }
  &-inline {
    padding: 0;
    input[type="date"] {
      margin: 0;
      padding: 0;
      text-align: center;
      width: 8.6rem;
      height: 1.5rem;
      display: inline-flex;
    }
  }
}

.contact-select-field {
  border: 1px solid $light-grey;
  padding: $row-padding*1.5;
  margin-bottom: $input-field-margin-bottom;
}

@charset "utf-8";
@use "sass:map";

@import 'new_styles_settings.scss';

@import 'vendor/foundation_settings.scss';
@import '~foundation-sites/scss/foundation';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~react-image-crop/dist/ReactCrop.css';
@import '~react-phone-input-2/lib/high-res.css';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';

// @include foundation-global-styles;
// @include foundation-tabs;
@include foundation-reveal;
// @include foundation-close-button;
// @include foundation-typography;
// @include foundation-menu;
// @include foundation-badge;
// @include foundation-forms;
// @include foundation-menu-icon;
@include foundation-off-canvas;
// @include foundation-sticky;
@include foundation-switch;
// @include foundation-visibility-classes;
// @include foundation-button;
@include foundation-xy-grid-classes(
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: true,
  $block-grid: true,
  $collapse: true,
  $offset: true,
  $vertical-grid: true
);

@import 'elements/settings.scss';
@import 'elements/typography.scss';
@import 'elements/base.scss';

@import 'elements/accordion.scss';
@import 'elements/checkmark.scss';
@import 'elements/lists.scss';
@import 'elements/progress_bar.scss';
@import 'elements/tables.scss';
@import 'elements/dashboard.scss';
@import 'elements/messages.scss';
@import 'elements/badges.scss';
@import 'elements/icons.scss';
@import 'native.scss';
@import 'utilities.scss';
@import 'elements/documents.scss';
@import 'elements/pulse.scss';
@import 'elements/account_switcher.scss';
@import 'elements/avatar.scss';
@import 'elements/charts.scss';
@import 'elements/row_options.scss';
@import 'elements/error_messages.scss';
@import 'elements/qr_code.scss';
@import 'elements/certificate_details.scss';
@import 'print.scss';
@import 'styles_old_assorted.scss';

@import 'elements/loader.scss';
@import 'new_styles_rules.scss';
@import 'elements/new/forms.scss';

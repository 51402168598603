/* = Font sizes = */
$font-size-small: 0.95rem;
$font-size-smaller: 0.875rem;
$font-size-tiny: 0.75rem;

/* = Headings = */

h6 {
  margin: $row-padding;
}

.font-size-16px {
  font-size: 0.90909rem;
}

.login-heading {
  text-align: center;
  padding-bottom: 1.5rem;
  margin: 0;
  letter-spacing: 0.15em;
  font-size: 2rem;
  @include breakpoint (medium) {
    font-size: 2.27273rem;
  }
}

.subtitle {
    color: $medium-grey;
    font-weight: $font-weight-bold;
    font-size: $font-size-smaller;
  }

.fa, .far {
  color: $medium-grey;
}

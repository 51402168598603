
.row-options-list {
    &.not-padded{
        padding:0;
    }
    display: flex;
    justify-content: flex-end;
    right: 4px;
    position: absolute;
    height: 94%;
    align-items: stretch;
    padding-bottom: 1rem;
    &.options-1 {
        width: 17%;
        @include breakpoint(medium) {
            width: 7%;
        }
        .option {
            width: 100%;
        }
    }
    &.options-2 {
        width: 36%;
        @include breakpoint(medium) {
            width: 16%;
        }
        .option {
            width: 50%;
        }
    }
    &.options-3 {
        width: 52%;
        @include breakpoint(medium) {
            width: 25%;
        }
        .option {
            width: 33.33%;
        }
    }
    .option {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        flex-direction: column;
        flex-wrap: nowrap;
        text-align: center;
        line-height: 0.8rem;
        &.primary-blue {
            background: $primary-color;
        }
        &.warning-yellow {
            background: $warning-color;
        }
        &.success-green {
            background: $success-color;
        }
        &.alert-red {
            background: $alert-color;
        }
        .fa, .far, .fas {
            color: $white;
        }
    }
    form {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        display: flex;
        width: 100%;
        &.buttons-1 {
            width: 50%;
            button{
                width: 100%;
            }
        }
    }
}

.movable-row {
    background-color: $color-white-06;
    left: 0;
    transition: left 350ms ease-in-out;
    &.row-flex {
        width: 100%;
    }
    &.move-left {
        &.items-1 {
            left: -17%;
            @include breakpoint(medium) {
                left: -7%;
            }
        }
        &.items-2 {
            left: -36%;
            @include breakpoint(medium) {
                left: -16%;
            }
        }
        &.items-3 {
            left: -52%;
            @include breakpoint(medium) {
                left: -25%;
            }
        }
    }
}

/* === Step progress bars ===*/

.progress {
  position: relative;
  padding-left: 45px;
  margin-top: 1.5rem;

  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 14px;
    width: 10px;
    height: 98%;
    border-left: 2px solid $light-grey;
  }

  &-item {
    position: relative;
    counter-increment: list;
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
      line-height: 0.9rem;
    }

    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      left: -31px;
      height: 100%;
      width: 10px;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0.3rem;
      left: -37px;
      width: 15px;
      height: 15px;
      border: 2px solid $light-grey;
      border-radius: 50%;
      background-color: $white;
    }

    &.progress-item-complete {
      &::before {
        top: 0.3rem;
        border-left: 2px solid $primary-color;
      }
    }

    &.progress-item-current, &.progress-item-complete {
      &::after {
        border: 2px solid $primary-color;
        background-color: $primary-color;
      }
    }

    button {
      color: $dark-grey;
    }
  }
}

.horizontal.progress {
  display: flex;
  width: 100%;
  margin: .5rem 0 1.5rem;
  padding-left: 0;
  justify-content: space-between;
  padding-right: 0.8rem;

  &::before {
    top: -0.3rem;
    width: 100%;
    border-bottom: 2px solid $light-grey;
    border-left: 0;
    left: 0;
  }

  .progress-item {
    width:100%;
    span {
      position: absolute;
      transform: translateX(-40%);
      text-align: center;
      margin-top: 1.5rem;
      font-size: 0.875rem;
      line-height: 1rem;
      width: max-content;
    }
    &:last-child {
      padding-bottom: 0;
      line-height: 0.9rem;
      width: 0;
      span {
        transform: translateX(-80%);
        text-align: right;
        padding-right: 0.5rem;
      }
    }
    &:first-child, &:nth-child(2) {
      span {
        transform: translateX(0%);
        text-align: left;
      }
    }
    &::before {
      left: 0;
      height: 100%;
      width: 10px;
    }
    &::after {
      left: 0;

    }

    &.progress-item-complete {
      &::before {
        top: -0.33rem;
        border-bottom: 2px solid $primary-color;
        border-left: 0;
        width: 100%;
      }
    }
  }
}

.progress-item-current.has-error::after, .progress-item-complete.has-error::after {
  border: 2px solid #f64e31;
}

.plain-progress-bar {
  height: 3px;  /* Can be anything */
  position: relative;
  background: $light-grey;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  span {
    display: block;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: $primary-color;
    position: relative;
    overflow: hidden;

  }
}

html {
	 touch-action: manipulation;
}

/* = = = Media iPhone X = = = */

@media only screen
and (min-device-width: 375px)
and (max-device-width: 414px)
and (min-device-height: 812px)
and (max-device-height: 896px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) {

	.flash-message.move-message {
		bottom: 95px !important;
	}

	.sentry-error-embed-wrapper .sentry-error-embed {
		margin-top: 2rem !important;
	}
}

.avatar {
    .avatar-image {
        border-radius: 50%;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 25vw;
        height: 25vw;
        max-width: 45px;
        max-height: 45px;
        min-width: 30px;
        min-height: 30px;
        margin: auto;
        &::after {
            content: '';
            background: linear-gradient(rgba(255,255,255,.4),rgba(255,255,255,0));
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 50%;
        }
        .image {
            max-height: 100%;
            max-width: 100%;
            border-radius: 50%;
            z-index: 1;
        }
        &.profile {
            min-width: 100px;
            min-height: 100px;
            .avatar-initials {
                @include fluid-type($min_width, $max_width, 27px, 30px);
            }
        }
    }
    // TODO: replace with custom class
    #account-switcher-component > & {
        .avatar-image {
            width: $account-switcher-avatar-size;
            height: $account-switcher-avatar-size;
        }
        .avatar-initials {
            font-size: .85rem;
        }
    }

    .avatar-initials {
        color: white;
        font-weight: 400;
    }

    &.empty-account {
        .avatar-image {
            border: 1px solid $medium-grey;
        }
        .avatar-initials {
            color: $medium-grey;
        }
    }

    &.active-account {
        .avatar-image {
            box-shadow: 1px 1px 9px 1px rgba(85, 142, 213, 0.4);
        }
        .name {
            font-weight: 400;
        }
    }

    .avatar-name-left-container {
        display: grid;
        align-content: center;
        justify-content: end;
        padding-right: .5rem;
        text-align: right;
    }

    .name {
        text-align: center;
        font-size: .85rem;
        line-height: 1rem;
        margin-top: .5rem;
    }

    .badge-notification-counter {
        z-index: 10;
        position: absolute;
        left: auto;
        right: -.5rem;
        top: 0;
        margin-right: 0;
    }

    &.pending .avatar-image {
        background: $light-grey;
    }

    .current-image-container {
        margin: 0;
        width: 107px;
        .fa {
            position: absolute;
            top: 0;
            right: 0;
            height: 30px;
            width: 30px;
            background: rgb(255,255,255);
            padding: 8px;
            border-radius: 50%;
        }
        .image {
            max-height: 100px;
            max-width: 100px;
            border-radius: 50%;
            z-index: 1;
        }
    }
}

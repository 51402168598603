.document .doc-date {
    float: right;
    display: block;
}

.document .doc-subject {
    font-style: italic;
    display: block;
}

.file-thumbnail-button {
    line-height: inherit;
    color: #666;
    justify-content: space-between;
    width: 100%;
    text-align: left;
}

.document {
    .reveal {
        top: 2rem !important;
        width: 95%;
        height: 92%;
    }
    .modal-content {
        .icon {
            color: $medium-grey;
        }
        overflow-y: scroll;
        height: 88%;
        white-space: normal;
    }
}

.document-file-preview-wrapper {
    width: 100%;
    min-height: 70vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.document-file-preview {
    width: 100%;
    min-height: 70vh;
}
.document-file-image-wrapper {
    img {
        margin: $row-padding auto;
        display: block;
    }
}
/* = = = Print styles = = = */

@media print {
  body * {
    visibility: hidden;
  }
  .visible-for-print, .visible-for-print * {
    top: 0 !important;
    visibility: visible !important;
    overflow: visible !important;
    margin-bottom: 0;
  }
  .absolute-print {
    @extend .visible-for-print;
    position: absolute;
    left: 0;
    width: 100%;
  }

  .reveal, .reveal-overlay, .is-reveal-open {
    position: absolute;
    top: 0 !important;
    visibility: visible !important;
    overflow: visible !important;
    overflow-y: visible !important;
  }

  .title-text {
    left: 0;
    right: 0;
    text-align: center;
  }
}

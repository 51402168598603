.loader,
.loader:after {
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}
.loader {
  top: 46%;
  left: 46%;
  display: inline-block;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: $loader-stroke-width solid $loader-color-2;
  border-right: $loader-stroke-width solid $loader-color-2;
  border-bottom: $loader-stroke-width solid $loader-color-2;
  border-left: $loader-stroke-width solid $loader-color-1;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.component-loader.overlay {
  position: relative;
  margin: 0 46%;
  .loader {
    position: absolute;
  }
}

.component-loader.overlay + .overlaid {
  opacity: 0.2;
  z-index: -1;
  position: relative;
}

.component-loader.overlay + .foreground{
  position: relative;
}

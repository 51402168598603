.pulse {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 10px;
}

.pulse.ok {
    animation: pulse_ok 1s infinite;
    box-shadow: 0 0 0 $dashboard-green;
    background: $dashboard-green;
}

.pulse.critical {
    animation: pulse_critical 0.5s infinite;
    box-shadow: 0 0 0 $dashboard-light-red;
    background: $dashboard-red;
}

.pulse.warning {
    animation: pulse_warning 1s infinite;
    box-shadow: 0 0 0 $dashboard-light-yellow;
    background: $dashboard-yellow;
}

.pulse.inline-pulse {
    display: inline-flex;
    vertical-align: bottom;
    margin-right: 1em;
}

@keyframes pulse_ok {
    0% {
        box-shadow: 0 0 0 0 $dashboard-light-green;
    }
    70% {
        box-shadow: 0 0 0 10px #f9fcfb;
    }
    100% {
        box-shadow: 0 0 0 0 #f9fcfb;
    }
}


@keyframes pulse_warning {
    0% {
        box-shadow: 0 0 0 0 $dashboard-light-yellow;
    }
    70% {
        box-shadow: 0 0 0 10px #f9fcfb;
    }
    100% {
        box-shadow: 0 0 0 0 #f9fcfb;
    }
}

@keyframes pulse_critical {
    0% {
        box-shadow: 0 0 0 0 $dashboard-light-red;
    }
    70% {
        box-shadow: 0 0 0 10px #f9fcfb;
    }
    100% {
        box-shadow: 0 0 0 0 #f9fcfb;
    }
}


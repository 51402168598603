
.inactive {
    .title {
    color: $medium-grey !important;
    }
    .svg-icon {
    fill: $light-grey !important;
    stroke: $very-light-grey !important;
    }
}

.dashboard {
    display: grid;
    grid-column-gap: 12px;
    column-gap: 12px;
    grid-row-gap: 12px;
    row-gap: 12px;
    margin: $tile-grid-margin;
    grid-template-columns: repeat(3, 1fr);
    @include screen-gt-xs {
        grid-template-columns: repeat(4, 1fr);
    }
    .widget {
        padding: $row-padding/2;
        width: 26vw;
        height: 26vw;
        float: left;
        @include screen-gt-xs {
            width: 15vw;
            height: 15vw;
            max-width: 200px;
            max-height: 200px;
        }
        .title {
            font-size: 0.79rem;
            top: 35%;
            position: absolute;
            color: $darker-grey;
            max-width: 80%;
        }
        .inner {
            height: 100%;
            position: relative;
        }
    }
}

/* = Generic = */

html {
  min-height: 100%;
  -webkit-tap-highlight-color: transparent !important;
}

p {
  padding: $row-padding;
  .not-padded &, [class^="css-"] & {
    padding: 0;
  }
}

/* = Content = */
// .content {
//   padding: 4rem 0 4.5rem;
//   @include breakpoint(medium) {
//     padding-top: 3.475rem;
//   }
// }

.content-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.image-preview {
  max-width: 50px;
  max-height: 50px;
  display: inline-block;
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}
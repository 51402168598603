@charset "utf-8";

/* = = = Documents = = = */

.document-file {
  .subject {
    display: block;
  }
  .icon {
    margin-right: $bp*2;
  }
  .left-padding {
    padding-left: 6.2rem;
  }
}

.document-file-preview-wrapper {
  width: 100%;
  min-height: 70vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.document-file-preview {
  width: 100%;
  min-height: 70vh;
}
.document-file-image-wrapper {
  img {
    margin: $row-padding auto;
    display: block;
  }
}


.shown-on-input-focus {
  display:none;
  input:focus + & {
    display: block;
  }
}

.form {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}
.form p {
    padding: 0;
}

.bold-labels label {
  font-weight: 500;
}

p > a {
  color: $primary-color;
}

.text-secondary {
  color: $medium-grey;
  &.small {
    font-size: $font-size-smaller;
  }
}
.text-primary {
  color: $primary-color;
}

.agenda-item.detail.voting {
  form {
    width: 100%;
    padding-top: 0;
  }
}

.off-canvas {
  max-width: 350px;
}

.selected-dial-code {
  font-size: 0.90909rem;
}

.description-style {
  ul, li, ol {
  padding-left: 30px;
  }
  ul > li::before {
    content: '\2022';
    padding-right: 7px;
  }
  ol {
    list-style: inside decimal;
  }
}

#sortable_textarea_list {
  li:first-child {
    border-top-width: 0;
  }
}

.ql-snow .ql-editor { h1,
                      h2,
                      h3,
                      h4,
                      h5,
                      h6 {
  color: $black;
}
}

.detail-view-comment {
  font-size: $font-size-smaller;
  // .title {
  //   font-weight: 500;
  // }
}

.p-row {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint(large) {
    max-width: 85%;
  }
}

.chart-area {
  display: block;
  width: 550px;
  height: 250px;
  position: relative;
  margin: auto;
  @include breakpoint(small only) {
    width: 100%;
    height: 200px;
  }
}

.new-valuation {
  color: $primary-color;
  &.available {
    float: right;
  }
}

// will be removed
.source-type {
  .svg-icon {
    margin: 0 8px;
    width: 18px;
    stroke: $medium-grey;
  }
  &.assets{
    .svg-icon {
      width: 15px;
    }
  }
  &.active {
    .svg-icon {
      stroke: $dark-grey;
      fill: $light-grey;
    }
  }
}

  /* = = = Media for different screen sizes = = = */

  @media only screen
  and (orientation: portrait) {
    .row-card {
      dd {
        width: 50%;
      }
      dt {
        width: 50%;
      }
    }
  }


/* === Modals ==== */

@include breakpoint(small only) {
  .reveal{
    min-height: 20%;
    height: auto;
    width: 90%;
    margin: auto;
    border-radius: 16px;
  }

  html.is-reveal-open, html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: relative;
 }
}

// .title-text {
//   vertical-align: super;
//   margin-left: 1.5rem;
// }

.close-button {
    position: absolute;
    cursor: pointer;
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1;
}

.documentation-container {
  .back-button {
    @extend .close-button;
    left: 1rem;
    right: auto;
    font-size: 1.2em;
    top: 1rem;
  }
}
.documentation-content {
  padding-top: $row-padding*2;
  h2 {
    margin-top: 0.5rem;
  }
  p {
    padding: 0 0 $row-padding;
  }
  ol {
    list-style-type: decimal;
    padding-left: 1rem;
  }
}

/* = = = Unused = = = */

.svg-logo {
  display: block;
  margin: 60px auto;
  &.immupass{
    max-width: 200px;
    margin: auto;
  }
}

.section-header .icon {
  color: $primary-color;
}

code {
  border: none;
  overflow-wrap: break-word;
}

.md-content {
  a {
    color: $dashboard-dark-blue;
  }
  ul, table {
    margin-bottom: 1rem;
  }
  p {
    padding: 0;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul {
      list-style-type: disc;
      margin-left: 1.25rem;
  }
}

.beneficial-owner-details {
  margin-top: 1rem;
  position: relative;
  // TODO: remove in favor of more generic class
  .beneficial-owner-remove-button {
    position: absolute;
    color: $primary-color;
    right: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.ReactCrop__crop-selection {
  border: none;
}
.ReactCrop {
  background: transparent;
  .ord-nw, .ord-ne, .ord-sw, .ord-se {
    display: block;
    background-color: inherit;
    height: 20px;
    width: 20px;
    border: none;
  }
  .ord-se {
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
  }
  .ord-nw {
    border-left: 2px solid $white;
    border-top: 2px solid $white;
    margin-top: -1px;
    margin-left: -1px;

  }
  .ord-ne {
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    margin-top: -1px;
    margin-right: -1px;
  }
  .ord-sw {
    border-left: 2px solid $white;
    border-bottom: 2px solid $white;
    margin-bottom: -1px;
    margin-left: -1px;
  }
}

// TODO move to dedicated module
.reveal {
  // TODO .reveal should extend .box-shadow(-light)
  box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 20px 0px;
  &:focus {
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px 0px;
  }
}
.modal {
    &.reveal-overlay {
        display: block;
    }
    .reveal {
        display: block;
        top: 128px;
        .reveal-content {
          padding-top: $row-padding*2;
        }
    }
    .subject {
        display: block;
    }
    .icon {
        color: $primary-color;
    }
    .left-padding {
        padding-left: 6.2rem;
    }
}

// TODO move to dedicated module
.scrollable-content-area {
    max-height: 300px;
    max-width: 75vw;
    overflow: hidden;
    overflow-y: auto;
    margin: 0 auto 1rem;
    font-size: 0.75rem;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, .25);
    background: rgba(0, 0, 0, .01);
    padding: 1rem;
    h1 {
      font-size: 1.25rem;
    }
    h2 {
      font-size: 1rem;
    }
    h3 {
      font-size: 0.8rem;
    }
    h4 {
      font-size: 0.75rem;
    }
    h2, h3, h4 {
      margin-top: 1rem;
    }
    table {
      table-layout:fixed;
      thead {
        display: none;
      }
      @include breakpoint(small only) {
        &, tbody, tr, td {
          display: block;
        }
        td:last-child {
          border-bottom: none;
        }
      }
      @include breakpoint(medium) {
        td {
          padding: $row-padding;
        }
        td:last-child {
          padding-right: 0;
        }
        td:first-child {
          padding-left: 0;
          vertical-align: top;
          width: 33%;
        }
      }
    }
}

/* = = = Badges = = = */

.badge-notification-counter {
  @extend .badge;
  @extend .success;
  float: right;
  &.messages-counter {
    background: rgba(0, 113, 255, 0.7);
    display: block;
    min-width: 0;
    width: 0.65rem;
    height: 0.65rem;
  }

  .no-margin & {
    margin: 0;
  }
  .left-margin & {
    margin-left: 0.9rem;
  }
}

.notification-text, .badge-notification-text {
  color: $white;
}

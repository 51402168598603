@charset "utf-8";

// Reset
html {
  box-sizing: border-box;
  font-family: Manrope, sans-serif;
  color: $color-text-default;
}
* {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
}
ol, ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

button, .button {
  font-family: Manrope, sans-serif;
  color: $color-neutral-10;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: $button-cursor;
}

dd[class^="css-"] {
  text-align: unset;
}

// Font & Typography
/* stylelint-disable-next-line no-duplicate-selectors */
body {
  font-family: Manrope, sans-serif;
  @include typography("body-1");
}
strong {
  font-weight: $font-weight-bold;
}
p {
  margin: $paragraph-margin;
}

[class^="css-"] {
  *, *:before, *:after {
    box-sizing: inherit;
  }
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
  p {
    font-size: $p-font-size;
    line-height: $p-line-height;
  }
}

// Typography: Utility Classes
// @todo consider whether to use typography utility classes or not
@each $key in map.keys($typography) {
  .typography-#{$key} {
    @include typography(#{$key});
  }
}

// Typography: Headings
@for $i from 1 through 5 {
  h#{$i} {
    @include typography("heading-#{$i}");
  }
}

// Body
/* stylelint-disable-next-line no-duplicate-selectors */
body {
  background: $body-bg-color;
}

/* = = = Components Styles = = = */
// Links
/* stylelint-disable-next-line no-duplicate-selectors */
a {
    color: $link-text-color;
    @include on-hover {
      color: $link-hover-text-color;
    }
    @include on-focus {
        color: $link-focus-text-color;
        // @todo consider outline mixin
        // box-shadow is used instead of outline to enable cross-browser rounded corners
        outline: none;
        box-shadow: 0 0 0 $link-focus-outline-width $link-focus-outline-color;
        border-radius: $link-focus-outline-radius;
    }
    &.disabled {
        color: $link-disabled-text-color;
        cursor: $link-disabled-cursor;
        @include on-focus {
            outline: none;
            // @todo consider outline mixin
            // box-shadow is used instead of outline to enable cross-browser rounded corners
            box-shadow: none;
        }
    }
}

// Buttons
.button, a.button {
    @include typography('button-1');
    display: flex;
    align-items: center;
    justify-content: center;
    background: $button-bg;
    border: $button-border-width solid $button-bg;
    border-radius: $button-border-radius;
    padding: $button-padding;
    margin: $button-margin;
    cursor: $button-cursor;
    color: $button-text-color;
    max-width: $button-max-width;
    text-align: center;
    white-space: nowrap;
    @include screen-lt-m {
      width: $button-width-lt-m;
    }
    .icon {
      padding-right: $button-icon-padding;
      color: $button-icon-color;
    }
    @include on-hover {
        background: $button-hover-bg;
        border-color: $button-hover-bg;
    }
    @include on-focus {
        background: $button-focus-bg;
        border-color: $button-focus-border-color;
        // @todo consider outline mixin
        // box-shadow is used instead of outline to enable cross-browser rounded corners
        outline: none;
        box-shadow: 0 0 0 $button-focus-outline-width $button-focus-border-color;
    }
    &:disabled, &.disabled {
        color: $button-text-color;
        background: $button-disabled-bg;
        border-color: $button-disabled-bg;
        cursor: $button-disabled-cursor;
    }
    &.button-outlined {
        background: $button-outlined-bg;
        color: $button-outlined-text-color;
        border-color: $button-outlined-border-color;
        .icon {
          color: $button-outlined-icon-color;
        }
        @include on-hover {
            color: $button-outlined-hover-text-color;
            border-color: $button-outlined-hover-border-color;
            .icon {
              color: $button-outlined-hover-icon-color;
            }
        }
        @include on-focus {
            color: $button-outlined-focus-text-color;
            border-color: $button-outlined-focus-border-color;
            .icon {
              color: $button-outlined-focus-icon-color;
            }
        }
        &:disabled, &.disabled {
            color: $button-outlined-disabled-text-color;
            border-color: $button-outlined-disabled-border-color;
            .icon {
              color: $button-outlined-disabled-icon-color;
            }
        }
    }
    &.button-borderless {
        color: $button-borderless-text-color;
        background: transparent;
        border: none;
        outline: none;
        .icon {
          color: $button-borderless-icon-color;
        }
        @include on-hover {
            background: $button-borderless-hover-bg;
        }
        @include on-focus {
            background: $button-borderless-focus-bg;
        }
        &:disabled, &.disabled {
            color: $button-borderless-disabled-text-color;
            background: transparent;
            .icon {
              color: $button-borderless-disabled-icon-color;
            }
        }
    }
	&.tab-button {
    margin: 0;
		color: $tab-text-color;
		@include on-hover {
			background: $tab-hover-bg;
			border-radius: $tab-border-radius;
		}
		&.active {
			background: $tab-active-bg;
        	color: $tab-text-active-color;
		}
	}
    &.button-small {
        padding: $button-small-padding;
        width: $button-small-width;
    }
    &.button-large {
        padding: $button-large-padding;
    }
    &.button-inverted {
      background: $button-inverted-bg;
    }
}

// Container
.app-container {
  display: grid;
  grid-template-areas: $grid-template-areas;
  grid-template-rows: $header-height auto;
  grid-template-columns: auto 1fr;
  min-height: 100vh;
  @include screen-lt-l {
    grid-template-columns: $menu-collapsed-width 1fr;
  }
  @include screen-xs {
    grid-template-areas: $grid-template-areas-xs;
    grid-template-columns: 1fr;
    grid-template-rows: $header-height-xs auto;
  }
  .app-android-safe-area & {
    grid-template-rows: $header-height-android auto;
  }
}

// Layout
.app-navigation-wrapper {
  z-index: $app-navigation-z-index;
  grid-area: navigation;
}

.app-navigation-placeholder {
  @include screen-gt-m {
    width: $menu-expanded-width;
  }
}

// Badges
@mixin badge-absolute {
  top: $badge-absolute-top;
  position: absolute;
}
.badge {
  @include typography('badge-1');
  background-color: $badge-bg;
  min-width: $badge-size;
  padding: $badge-padding;
  border-radius: $badge-border-radius;
  color: $badge-text-color;
  margin-left: auto;
  text-align: center;
  @include screen-xs {
    .menu-favorites & {
      @include badge-absolute;
      left: $badge-absolute-left;
    }
  }
}
.badge-dot {
  background-color: $badge-dot-bg;
  width: $badge-dot-size;
  height: $badge-dot-size;
  border-radius: $badge-dot-size;
  z-index: $badge-dot-z-index;
  border: $badge-dot-border-width solid $badge-dot-border-color;
  top: $badge-dot-top;
  right: $badge-dot-right;
  position: absolute;
  display: block;
}

// Main Column
.main {
  grid-area: main;
}

// Sidebar
// @todo replace with vars
.sidebar {
  grid-area: sidebar;
  @include screen-xs {
    display: none;
  }
  >section {
    top: 64px;
  }
}

// Header
.app-header {
  @include backdrop-blur;
  @include app-content-padding-h;
  min-height: $header-height;
  z-index: $app-header-z-index;
  grid-area: header;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.is-sticky {
    border-bottom: $app-header-border-width solid $app-header-border-color;
  }
  @include screen-xs {
    padding-top: env(safe-area-inset-top);
    min-height:  $header-height-xs;
  }
  .app-android-safe-area & {
    padding-top: $android-safe-area-top;
  }
}

.back-button {
  padding: $back-button-padding;
  line-height: $back-button-line-height;
  margin-right: auto;
  position: relative;
  left: -1*$back-button-padding;
}
.back-button-icon {
  width: $back-button-icon-size;
}

// Account Switcher
.account-name {
  margin: $account-switcher-name-margin;
  @include screen-xs {
    display: none;
  }
}
.account-switcher-container {
  background: $account-switcher-bg;
  border-left: $account-switcher-border-width solid $account-switcher-border-color;
  padding-top: env(safe-area-inset-top);
  .app-android-safe-area & {
    padding-top: $android-safe-area-top;
  }
  a {
    color: $account-switcher-link-color;
  }
}
.account-switcher-header {
  padding: $account-switcher-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Content
.page-content {
  @include app-content-padding-h;
  @include app-content-padding-bottom;
  &.not-padded {
    padding: 0;
  }
}

// Page Title
.intersection-observer {
  height: $header-height;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: -10;
}
.page-title {
  @include app-content-padding-h;
  margin: $page-title-margin;
  line-height: $header-height;
  z-index: $page-title-z-index;
  max-width: calc(100vw - $menu-collapsed-width);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: sticky;
  transform-origin: left;
  top: 0;
  // remove for animated header
  @include screen-xs {
    @include typography('heading-5');
    top: $page-title-top-xs;
    position: fixed;
    left: 66px;
    right: 66px;
    text-align: center;
    padding: 0;
  }
  .app-android-safe-area & {
    top: $page-title-top-android;
  }
}

// @todo DRY if subtitle stays
.page-subtitle {
  @include typography('sub-heading-1');
  color: $page-subtitle-color;
  top: calc($page-title-top-xs + $bp*8);
  position: fixed;
  text-align: center;
  left: 66px;
  right: 66px;
  z-index: 9;
  .app-android-safe-area & {
    top: calc($page-title-top-android + $bp*8)
  }
  @include screen-gt-xs {
    display: none;
  }
}

// Generic tiles parts
.tile-label-with-icon {
  display: flex;
  align-items: center;
  @include typography('label-1');

  .label {
    padding-left: $pricing-tile-label-padding-left;
  }
}
.tile-header {
  @include tile-header;
}
.tile-component {
  @include tile;
}

// Datalist tiles
.datalist-tiles {
  @include tiles;
  @include tiles-columns;
}
.datalist-tile {
  @include tile;
}

.datalist-header {
  @include tile-header;
}

.datalist-content {
  @include tile-content-padding;
}

.datalist-label {
  padding-top: $datalist-labe-padding-top;
  @include typography('body-2');
  color: $datalist-label-color;
}

.datalist-value {
  @include typography('title-2');
  color: $datalist-value-color;
}


// Pricing
.registration-pricing-info {
  display: grid;
  gap: $bp*6;
}

.pricing-plan-tiles {
  @include tiles;
  margin-bottom: 0;
  grid-template-columns: repeat($pricing-tiles-grid-columns, minmax($pricing-tile-min-width, 1fr));
}
.pricing-plan-tile {
  @include tile;
  min-height: $pricing-tile-min-height;
  position: relative;
  &.default-plan {
    min-height: $pricing-tile-min-default-height;
  }
  .button {
    width: $pricing-tile-button-width;
    @include screen-xs {
      width: $pricing-tile-button-width-xs;
    }
    position: absolute;
    bottom: $pricing-tile-button-bottom;
    left: $pricing-tile-button-left;
    right: $pricing-tile-button-right;
  }
}
.pricing-plan-tile-sub-header {
  @include typography('body-2');
  color: $pricing-tile-sub-header-color;
}
.pricing-plan-tile-content {
  padding: $pricing-tile-content-padding;
}
.pricing-plan-tile-section-separator {
  padding-bottom: $pricing-tile-label-padding-bottom;
  border-bottom: $pricing-tile-section-border-bottom;
}
.pricing-plan-items {
  padding-top: $pricing-tile-label-padding-top;
}
.pricing-plan-item {
  @include typography('body-2');
  align-items: center;
  display: flex;
  .label {
    padding-left: $pricing-tile-label-padding-left;
  }
  &.paid-item {
    color: $pricing-tile-paid-item-color;
    padding-left: $pricing-tile-paid-item-padding-left;
    justify-content: space-between;
    .paid-item-price {
      white-space: nowrap;
    }
  }
}

// Widgets
.widgets {
  @include tiles;
  @include tiles-columns;
  @include screen-gt-m {
    >div:nth-child(2n+1):last-child {
      grid-column: span 2;
    }
  }
}
.widget {
  @include tile;
}
.widget-header {
  @include tile-header;
}
.widget-content {
	@include tile-content-padding;
}
.widget-tabs {
  @include widget-sides-padding;
}
.content-monetary-value {
  @include typography('number-2');
  .currency {
    @include typography('number-4');
  }
}
.assets-value {
  padding: $bp*3 0px;
}
.widget-action {
    color: $color-primary;
    @include typography('body-2');
  }
.widget-content-row {
	@include widget-sides-padding;
	@include content-row-grid;
	padding-top: $content-row-padding;
	padding-bottom: $content-row-padding;
	@include on-hover {
		background-color: $color-white-100;
	}
	&.widget-row-expanded {
    background-color: $color-white-100;
    .widget-row-main-content {
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
      word-break: unset;
    }
  }
}
.widget-row-main-content {
	@include row-main-content;
}
.widget-row-avatar {
  .avatar {
    .avatar-image {
    width: $avatar-image-width;
    height: $avatar-image-height;
    }
  }
}
.widget-row-title {
	@include content-row-title;
}

.widget-left-column {
  @include typography('body-3');
	color: $content-row-color;
	white-space: nowrap;
	text-align: right;
}
.row-content-icon {
	vertical-align: bottom;
  	display: inline-block;
	margin-right: $widget-content-row-icon-margin;
}


// Tabs
.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: $tab-group-padding-bottom;
  border-bottom: $tab-group-border-bottom;
}

// Tooltips
.tooltip-link {
  color: $tooltip-text-color;
  @include on-hover {
    color: $tooltip-text-color;
  }
}
.tooltip-trigger {
  padding: $tooltip-trigger-padding;
  display: block;
  svg { display: block; }
  @include on-hover {
    background: $tooltip-trigger-hover-bg;
    border-radius: $tooltip-trigger-hover-border-radius;
    cursor: $tooltip-trigger-cursor;
  }
}

// Empty content
// @todo move values to settings
.empty-content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 232px;
  p {
    @include typography('body-2');
    padding: $bp*4 $bp*8;
    color: $color-neutral-40;
    margin: 0;
  }
}

// Documents (not new design)
.document-list-row {
	@include content-row-grid;
	padding: $content-row-padding;
	border-radius: $document-row-border-radius;
  background: $document-row-bg;
	margin-bottom: $margin-bottom;
	min-height: $document-row-min-height;
}
.document-row-title {
	@include content-row-title;
}
.document-row-content {
	@include row-main-content;
    align-items: flex-start;
}

// Tags

.tag {
  @include typography('label-2');
  color: $tag-color;
  background: $tag-bg-color;
  border: $tag-border;
  border-radius: $tag-border-radius;
  margin: $tag-margin;
  width: fit-content;
  width: -moz-fit-content;
  display: flex;
  align-items: center;
  padding: $tag-padding;
  .label {
    padding-left: $tag-label-padding-left;
  }
  &.tag-outlined {
    background: $tag-outlined-bg-color;
    border-color: $tag-outlined-border-color;
  }
  &.tag-success {
    border-color: $tag-success-border-color;
  }
}

// Inbox
.inbox-not-seen {
  font-weight: $font-weight-semi-bold;
}
.inbox-content {
  display: flex;
  flex-direction: column;
  gap: $bp;
}
.inbox-actions {
  display: flex;
  gap: $bp*8;
  & > a {
    @include typography('body-2');
    padding: 0;
    width: auto;
  }
  & > span {
    position: absolute;
    right: $bp*5;
    bottom: $bp*4;
    & > div {
      width: $bp*6;
      height: $bp*6;
      padding: 0;
    }
  }
  .card-base & {
    &>span {
      right: 10px;
      bottom: 10px;
    }
  }
}

// @todo move values to settings
.actions-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: $bp*4 0;
  .button {
    margin-left: $bp;
  }
}
.actions-bar-label {
  display: none;
}
.actions-form {
  padding: 0;
}
.app-container-anon {
  grid-template-columns: 0 auto 0;
  @include screen-lt-l {
    grid-template-columns: 0 auto;
  }
  @include screen-xs {
    grid-template-columns: 1fr;
  }
}
// @todo rename class
.app-container-nested {
  grid-area: main;
  background: $body-bg-color;
  z-index: $page-content-inserted-z-index;
}
.page-content-overlay-open, .contacts-overlay-open {
  &, &.app-android-safe-area {
    .app-container {
      grid-template-rows: 0 auto;
    }
  }
}
.investments-total-value {
  @include typography('number-2');
}


// User agreement
.user-agreement-container {
  table {
    table-layout: fixed;
  }
}

// Search input
.search-box {
  margin: 0 0 0 auto;
  width: $search-field-width;
  @include screen-xs {
    width: $search-field-width-xs;
  }
}
.input-icon {
  position: absolute;
  padding: $input-field-icon-padding;
}

.search-input[type='search'] {
  @include typography('body-2');
  border: $search-field-border-width solid $search-field-border-color;
  border-radius: $search-field-border-radius;
  height: $search-field-height;
  padding-left: $search-field-text-padding-left;
  background-color: $search-field-bg-color;
  color: $search-field-text-color;
  margin: $search-field-margin;
  &:focus {
    border: $search-field-border-width solid $search-field-focus-border-color;
    padding-left: $search-field-text-padding-left;
  }
  &::-webkit-search-decoration {
    display: none;
  }
}

// Main content area parts
.main-content-area {
  background: $main-content-area-bg;
  border-radius: $main-content-area-border-radius;
}
.main-content-area-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $main-content-area-header-padding-top $main-content-area-header-padding-left $main-content-area-header-padding-bottom;
  @include typography('heading-4');
  @include screen-xs {
    padding: $main-content-area-header-padding-top-xs $main-content-area-header-padding-left-xs $main-content-area-header-padding-bottom-xs;
    display: inline-block;
    width: $main-content-area-header-width;
  }
}
.main-content-area-title {
  @include screen-xs {
    padding-bottom: $main-content-area-title-padding-bottom-xs;
  }
}

// Tables
.table-content {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  td {
    @include screen-xs {
      &:nth-child(n+2):nth-child(-n+3) {
        color: $table-text-color-secondary;
        display: inline;
      }
      &:nth-child(2):after {
        content: ", ";
      }
    }
  }
  th, td {
    border-bottom: none;
    color: $table-text-color;
    padding: $table-cell-padding-top $table-cell-padding-right $table-cell-padding-bottom $table-cell-padding-left;
    @include screen-xs {
      padding: $table-cell-padding-xs 0;
    }
  }
  tr {
    border-bottom: $table-border-bottom;
    @include screen-xs {
      padding: $table-row-padding-xs;
      display: block;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  tbody {
    @include typography('body-2');
  }
  th {
    @include typography('label-1');
  }
  thead {
    @include screen-xs {
      display: none;
    }
  }
}

.table-body-actions-cell {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include screen-xs {
    justify-content: flex-start;
  }
  .button-small {
    margin: $table-cell-button-margin;
  }
}

// Pagination
.pagination-controller {
  @include typography('body-2');
  padding: $pagination-controller-padding;
  color: $pagination-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: $pagination-controller-width;
  @include screen-xs {
    display: none;
  }
  svg {
    display: block;
  }
}
.pagination-items-counter {
  padding-right: $pagination-items-counter-padding;
}

// Custodial account banner
.managed-account-banner {
  gap: $account-gap-xs;
  padding: $account-banner-top-padding $account-banner-sides-padding;
  height: $account-banner-height;
  align-items: center;
  background: $account-banner-bg;
  grid-area: header;
  z-index: $account-banner-z-index;
  top: $account-banner-top;
  position: sticky;
  display: grid;
  grid-template-columns: $account-banner-grid-columns;
  @include screen-xs {
    display: flex;
    flex-direction: column;
    min-height: $account-banner-height-xs;
    padding: $account-banner-top-padding-xs 0 $account-banner-bottom-padding-xs;
  }
  .app-android-safe-area & {
    padding-top: $account-banner-top-padding-android;
    height: $account-banner-height-android;
  }
  .button {
    display: flex;
    align-items: center;
    margin: 0;
    max-width: fit-content;
    justify-self: end;
  }
}

.managed-account-text {
  grid-column: $account-text-column;
  text-align: center;
  @include typography('body-2');
}
.managed-account-name {
  font-weight: $account-name-weight;
}

.managed-account {
  .app-header {
    top: $account-banner-height;
    height: $header-height;
    min-height: $header-height;
    padding-top: 0;
    @include screen-xs {
      top: $account-banner-height-xs;
      height: $header-height-net-xs;
      min-height: $header-height-net-xs;
      padding-top: 0;
    }
  }
  .app-container {
    grid-template-rows: $header-with-banner-height auto;
    @include screen-xs {
      grid-template-rows: $header-with-banner-height-xs auto;
    }
  }
  .page-title {
    top: $page-title-with-banner-top;
    @include screen-xs {
      top: $page-title-with-banner-top-xs;
    }
  }
  &.app-android-safe-area {
    .app-header {
      top: $account-banner-height-android;
      padding-top: 0;
    }
    .app-container {
      grid-template-rows: $header-with-banner-height-android auto;
    }
    .page-title {
      top: $page-title-with-banner-top-android;
    }
  }
  &.page-content-overlay-open, &.contacts-overlay-open {
    .app-container {
      grid-template-rows: 0 auto;
    }
  }
}

.info-text {
  @include typography('body-2');
  color: $info-text-color;
}


.file-content-container {
  display: grid;
  grid-template-columns: $file-content-container-preview-width auto max-content;
  grid-column-gap: $file-content-container-column-gap;
  white-space: nowrap;
  padding-bottom: $file-content-container-padding;
  .file {
    img {
      width: $file-info-preview-width;
    }
    .icon {
      font-size: $file-info-icon-height;
      width: $file-info-preview-width;
    }
  }
  .file-info {
    @include typography('title-2');
    color: $file-info-color;
    overflow: hidden;
    text-overflow: ellipsis;
    .file-name {
      padding-bottom: $file-info-items-padding;
    }
    .file-type {
      color: $file-info-type-color;
      padding-bottom: $file-info-items-padding;
    }
    .action {
      @include typography('title-2');
      color: $file-info-action-color;
    }
  }
  .file-size {
    @include typography('body-3');
  }
}


// Modal

.modal {
  .reveal {
    padding: $modal-padding;
  }
}
.modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.modal-content {
  @include typography('title-2');
  padding-top: $modal-content-padding;
}


// Temp
a.grid-x, a.row-flex {
  color: $color-text-default;
}
.document-attachment-wrapper {
  height: 14vh;
  img {
    max-height: 100%;
    max-width: 90%;
  }
  .icon {
    font-size: 4rem;
    text-align: left;
    color: $light-grey;
  }
}
.document-attachment-detail-wrapper {
  width: 50vw;
}
.show-for-sr {
  display: none;
}

.help-text {
  padding-bottom: 12px;
  @include typography('body-2');
}

// MUST BE REMOVED as soon as shareholders view is rewritten in react
.single-shareholder-excerpt-text {
  button {
    span {
      font-size: $font-size-tiny;
    }
  }
}

// Subscriptions
.subscription-subheading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  .avatar .avatar-image {
    width: 32px;
    height: 32px;
  }
}
.subscription-issue-price-calc {
  margin: -20px 0 40px;
  dd {
    font-weight: $font-weight-bold;
    text-align: left;
  }
}

// Account Deletion Page
.page-width-limit {
  max-width: 1136px;
  margin: 0 auto;
}
.app-container-account-deletion {
  font-size: 14px;
  .app-header {
    justify-content: space-between;
    max-height: 65px;
  }
  .svg-logo {
    height: 90px;
    width: 128px;
    margin: 0;
    left: -24px;
    position: relative;
  }
  .button-login {
    margin: 0;
    @include screen-xs {
      width: auto;
      padding: 8px 16px;
    }
  }
  .page-content > .tile-component {
    padding: 2rem;
  }
  hr {
    opacity: .25;
    margin: 2rem 0;
  }
}

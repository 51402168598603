/* = = = Utilities = = = */

/*
Table of Contents:
  - Display & Positioning
  - Paddings & Margins
  - Font
  - Aligns
  - Long context text
  - Alerts
  - Links
  - Sizing
  - Borders
  - Colours
*/

.column-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  max-width: 400px;
  float: left;
  @include breakpoint(medium) {
    margin: 1rem;
  }
  @include breakpoint(small only) {
    width: 100%;
  }
}


/* = Display & Positioning = */
.hidden, .deactivated {
  display: none !important;
}

.centered {
    display: flex;
    justify-content: center;
}
.icon-relative-positioning {
  .row-icon {
    position: relative;
  }
}
.display-block {
  display: block;
}
.limited-width {
  display: block;
  width: 75vw;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

// .app-header-logged-in ~ .grid-container, .app-header-logged-in {
//   @include breakpoint(medium) {
//     padding-left: 130px;
//   }
// }
// .grid-container {
//   @include breakpoint(small only) {
//     .content {
//       .actions-bar ~ & {
//         padding-bottom: 7rem;
//         .move-message {
//           bottom: 7rem;
//         }
//       }
//     }
//   }
// }

/* = Paddings & Margins = */
.not-padded {
  &, &.row-flex {
    padding: 0;
  }
}
.padded-y {
  &, &.row-flex {
    padding: $row-padding 0;
  }
}
.padded-x {
  &, &.row-flex {
  padding-left: $row-padding;
  padding-right: $row-padding;
    }
}
.padded-top {
  padding: $row-padding 0 0;
}
.large-padding {
  padding-top: 2rem;
}
.reduced-padding-y {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.left-padding {
  padding-left: $row-padding;
}
.tiny-left-padding {
  padding-left: 0.15rem;
}
.left-padding-3-5-rem {
  padding-left: 3.5rem;
}
.left-padding-card {
  padding-left: 4.5rem;
}
.right-padding {
  padding-right: $row-padding;
}
.bottom-padding {
  padding-bottom: $row-padding;
}

.no-margin {
  &, .badge-notification-counter {
    margin: 0;
  }
}
.top-margin {
  margin-top: 0.9rem;
}
.big-top-margin {
  &, &.button {
    margin-top: 2rem;
  }
}
.big-bottom-margin {
  margin-bottom: 5rem;
}
.bottom-margin-1-2-rem {
  margin-bottom: 1.2rem;
}
.left-margin {
  &, .badge-notification-counter {
  margin-left: 0.9rem;
  }
}
.bottom-margin {
  margin-bottom: 0.5rem;
}
.margin-y {
  margin: 0.5rem 0;
}

/* = Font = */
.text-small {
  font-size: $font-size-small;
}
.text-smaller {
  font-size: $font-size-smaller;
}
.text-tiny, .helptext {
  font-size: $font-size-tiny;
}
.text-big {
  font-size: 1.3*$global-font-size;
}
.text-size-label {
  font-size: $form-label-font-size;
}
.font-bold {
  font-weight: 500;
}
.font-normal {
  font-weight: 300;
}
.font-medium {
  font-weight: 400;
}
.font-italic {
  font-style: italic;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  /*
  https://css-tricks.com/snippets/css/fluid-typography/#article-header-id-0
  */
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

/* = Aligns = */
.align-flex-end {
  align-self: flex-end;
}
.align-flex-start {
  align-self: flex-start;
}
.align-self-normal {
  align-self: normal;
}
.align-flex-items-start {
  align-items: flex-start;
}
.align-flex-items-center {
  align-items: center;
}
.align-text-center {
  text-align: center;
}
.align-text-right {
	text-align: right;
}
.align-text-left {
  text-align: left;
}
.justify-content-space-between {
  display: flex;
  justify-content: space-between;
}
.justify-content-space-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-content-flex-end {
  display: flex;
  justify-content: flex-end;
}

.text-center-on-small {
  @include breakpoint(small only) {
    text-align: center;
  }
}

/* = Long context text = */
.overflow-wrap {
  overflow-wrap: break-word;
}
.white-space-wrap {
  white-space: normal,
}
.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.no-wrap {
  white-space: nowrap;
}
.flex-wrap {
  flex-wrap: wrap;
}

/* = Alerts = */
.alert {
  color: $alert-color;
}
.warning {
  background: $warning-color;
}
.success-color {
  color: $success-color;
}

/* = Links = */
.element-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $row-padding;
  box-sizing: border-box;
  & + * {
    margin-top: 2*$row-padding;
  }
}
.cancel-link {
  display: flex;
  justify-content: flex-end;
  font-weight: $light-grey;
  margin-bottom: 0.7rem;
  @include breakpoint (small only) {
    font-size: small;
  }
}

.empty-button {
  form &,
  &.hollow,
  &:hover,
  &.hollow[disabled],
  &.hollow:hover[disabled],
  &[disabled],
  & {
    margin-top: 0;
    height: auto;
    max-width: fit-content;
    max-width: -moz-fit-content;
    padding: 0;
    background: none;
    border: none;
  }
}

.no-border-button {
  form &,
  &.hollow,
  &.hollow[disabled],
  &.hollow:hover[disabled],
  &[disabled],
  &:hover,
  & {
    border: none;
  }
}

/* = Sizing = */
.full-width {
  width: 100%;
  max-width: unset;
}
.min-height-fit-content {
  min-height: fit-content;
}

/* = Borders = */

.underline {
  @include border-bottom($light-grey);
}
.border-top {
  @include border-top($light-grey);
}
.border-left {
  @include border-left($light-grey);
}
.border-right {
  @include border-right($light-grey);
}
.borderless {
  border-bottom: none;
}
.dotted-border-bottom {
  border-bottom: 1px dotted $primary-color;
}


/* = Text colours = */
.medium-grey {
  color: $medium-grey;
}

.dark-grey {
  color: $dark-grey;
}

.disabled-text-link{
  color: $medium-grey;
}

.current-image-container {
  position: relative;
  width: 70%;
  margin: 25px auto 0;
  img {
    display: block;
    width: 100%;
    & + .fa {
      position: absolute;
      top: -15px;
      right: -15px;
      height: 30px;
      width: 30px;
      background: rgb(255,255,255);
      padding: 5px;
      border-radius: 50%;
    }
  }
}

/* = Settings = */
$row-padding: 0.625rem;
$color-dirty-white: #f9f9f9;
$font-weight-regular: 400;
$font-weight-light: 300;

$dark-blue: #2c589c;

/* Dashboard */
$dashboard-red: #e90000;
$dashboard-orange-red: #FF4100;
$dashboard-bright-orange: #ff7625;
$dashboard-orange: #fb9b00;
$dashboard-yellow: #ffd024;
$dashboard-dirty-yellow: #c19f23;
$dashboard-purple: #5338ff;
$dashboard-dark-blue: #1960b9;
$dashboard-blue: #2b89ff;
$dashboard-blue-green: #009fa7;
$dashboard-green: #00b583;
$dashboard-bright-green: #44DA7A;

$dashboard-light-red: lighten($dashboard-red,30%);
$dashboard-light-green: lighten($dashboard-green,30%);
$dashboard-light-yellow: lighten($dashboard-yellow,30%);


/* Native */
$color-local: #0076a3;
$color-development: #ec008c;
$color-demo: #39b54a;
$color-demo-preview: #00e7ea;
$color-preview: #aba000;
$color-staging: #f26522;


/* = Colors = */
$disabled-color: $grey;

.primary-color {
  &, &.fa, &.far, &.fas
  {
    color: $primary-color;
  }
}
.fa, .far, .fas {
  &.disabled {
    color: $light-grey;
  }
}
.secondary-color {
  color: $secondary-color;
}
.disabled-blue{
  color: $blue-disabled;
}
.fa.dashboard-blue-green {
  color: $dashboard-blue-green;
}

/* Blockchain Stats */
.best-block {
  color: $dashboard-purple;
}

.last-block {
  color: $dashboard-bright-orange;
}

.avg-block-time {
  color: $dashboard-blue;
}

.gas-price {
  color: $dashboard-blue-green;
}


/* = Mixins = */
@mixin border-left($color){
  border-left: 1px solid $color;
}
@mixin border-top($color){
  border-top: 1px solid $color;
}
@mixin border-bottom($color){
  border-bottom: 1px solid $color;
}
@mixin border-right($color){
  border-right: 1px solid $color;
}
@mixin border($color){
  border: 1px solid $color;
}


// TODO move elsewhere
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px 0px;
}


.address {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
  min-width: 10%;
}

.address-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 60%;
  min-width: 10%;
  .address {
    max-width: unset;
    min-width: unset;
  }
}

.address-label {
  min-width: max-content;
}
.full-width {
  &.address-wrapper {
    max-width: 100%;
    justify-content: flex-start;
  }
}
/* = Icons = */
.card-icon {
    .svg-icon {
        height: 20px;
        // position: absolute;
        stroke: $white;
        &.icon-document {
            fill: #2b89ff;
            transform: scaleX(0.9);
            stroke-width: 10px;
        }
        &.icon-document-signing {
            fill: $dashboard-blue;
            stroke: $dashboard-blue;
            stroke-width: 10px;
        }
        &.icon-certificates {
            fill: none;
            stroke: $dashboard-purple;
            stroke-width: 60px;
        }
        &.icon-subscriptions{
            fill: none;
            stroke: $dashboard-blue;
            stroke-width: 50px;
        }
        &.icon-certificate-types{
            fill: none;
            stroke: $dashboard-blue-green;
            stroke-width: 60px;
        }
        &.icon-board-members, &.icon-verify, &.icon-blockchain,
        &.icon-fx-prices
        {
            stroke: none;
            fill: $dashboard-blue;
            .rectangles {
                fill: $dashboard-green;
            }
        }
        &.icon-investments, &.icon-alternative-investments
        {
            fill: $dashboard-purple;
        }
        &.icon-liabilities {
            fill: $dashboard-purple;
            stroke-width: 1px;
        }
        &.icon-real-estate, &.icon-transactions
        {
            fill: $dashboard-yellow;
        }
        &.icon-notifications
        {
            fill: $dashboard-yellow;
            stroke: none;
        }
        &.icon-payments, &.icon-meetings {
            fill: $dashboard-green;
            stroke: none;
            font-weight: 900;
        }
        &.icon-profile {
            fill: $dashboard-red;
            transform: scale(1.1);
        }
        &.icon-orders {
            fill: $dashboard-dirty-yellow;
            stroke: $dashboard-dirty-yellow;
            stroke-width: 10px;
        }
        &.icon-shareholders {
            fill: $dashboard-orange;
            stroke: $dashboard-orange;
            stroke-width: 10px;
        }
        &.icon-beneficial-owners {
            fill: $primary-color;
            stroke: none;
        }
        &.icon-signatories {
            fill: $dashboard-orange-red;
            stroke-width: 1px;
        }
        &.icon-funds {
            fill: $dashboard-orange-red;
        }
        &.icon-insurance {
            fill: $dashboard-bright-green;
            stroke: none;
        }
        &.icon-payment-tokens {
            fill: $dashboard-bright-orange;
            height: 20%;
            stroke: none;
        }
        &.icon-coins {
            fill: $dashboard-bright-orange;
            stroke: $dashboard-bright-orange;
            stroke-width: 6px;
        }
        &.icon-identities {
            fill: $dashboard-dark-blue;
            transform: scale(0.87);
        }
        &.icon-arbiter {
            fill: $dashboard-dark-blue;
            stroke: none;
            }
        &.icon-signatures-and-roles{
            fill: $dashboard-orange;
            stroke: $dashboard-orange;
            stroke-width: 4px;
        }
        &.icon-help {
            fill: $dashboard-blue-green;
            stroke: none;
        }
        &.blockchain-transactions-approve {
            stroke: $dashboard-blue-green;
            stroke-width: 25px;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
        &.validators {
            stroke: $dashboard-blue;
            stroke-width: 8px;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
        &.consortium {
            stroke: $dashboard-blue;
            stroke-width: 10px;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
        &.nodes {
            fill: $dashboard-blue;
            stroke: $dashboard-blue;
            stroke-width: 6px;
        }
        &.icon-ownership {
            fill: $dashboard-orange;
            stroke: none;
        }
    }
    &.disabled {
        .svg-icon  {
            fill: $light-grey;
            stroke: $very-light-grey;
        }
    }

    &.icon-padding {
        padding-right: 12px;
    }
    &.fa {
        font-size: 20px;
    }

}

.row-icon{
    // position: absolute;
    fill: none;
    height: 20px;
    &.validators {
        stroke: $dashboard-blue;
        stroke-width: 8px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    &.consortium {
        stroke: $dashboard-blue;
        stroke-width: 10px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    &.nodes {
        fill: $dashboard-blue;
        stroke: $dashboard-blue;
        stroke-width: 6px;
    }
    &.meetings {
        fill: $dashboard-bright-orange;
        stroke: none;
    }
    &.icon-blockchain {
        position: relative;
        fill: $primary-color;
        height: 25vw;
        max-height: 35px;
    }
}

.icon.secondary {
  color: $light-grey;
}

/* = Content = */
.content-icon {
  color: $color-neutral-70;
  height: 4rem;
  &.svg-icon {
    stroke: $color-neutral-70;
  }
}

/* = Dashboard & Bottom bar = */
.svg-navigation {
    fill: $primary-color;
    stroke: $white;
    stroke-width: 0;
    &.dashboard {
        stroke-width: 10px;
    }
}
.svg-icon {
    fill: none;
    stroke: $primary-color;
    stroke-width: 15px;
  .dashboard & {
    height: 18%;
    left: $row-padding;
    top: $row-padding;
    position: absolute;
    stroke: $white;
    stroke-width: 15px;
    &.icon-document {
        fill: $dashboard-blue;
        transform: scaleX(0.9);
        stroke-width: 10px;
    }
    &.icon-document-signing {
        fill: $dashboard-blue;
        stroke: $dashboard-blue;
        stroke-width: 10px;
    }
    &.icon-certificates {
        fill: none;
        stroke: $dashboard-purple;
        stroke-width: 60px;
    }
    &.icon-certificate-types {
        fill: none;
        stroke: $dashboard-blue-green;
        stroke-width: 60px;
    }
    &.icon-subscriptions{
        fill: none;
        stroke: $dashboard-blue;
        stroke-width: 50px;
    }
    &.icon-board-members, &.icon-verify, &.icon-blockchain,
    &.icon-fx-prices
    {
        stroke: none;
        fill: $dashboard-blue;
        .rectangles {
            fill: $dashboard-green;
        }
    }
    &.icon-investments, &.icon-alternative-investments
    {
        fill: $dashboard-purple;
    }
    &.icon-liabilities {
        fill: $dashboard-purple;
        stroke-width: 1px;
    }
    &.icon-real-estate, &.icon-transactions
    {
        fill: $dashboard-yellow;
    }
    &.icon-notifications
    {
        fill: $dashboard-yellow;
        stroke: none;
    }
    &.icon-payments, &.icon-meetings {
        fill: $dashboard-green;
        stroke: none;
        font-weight: 900;
    }
    &.icon-profile {
        fill: $dashboard-red;
        transform: scale(1.1);
    }
    &.icon-orders {
        fill: $dashboard-dirty-yellow;
        stroke: $dashboard-dirty-yellow;
        stroke-width: 10px;
    }
    &.icon-ownership {
        fill: $dashboard-orange;
        stroke: none;
    }
    &.icon-signatories {
        fill: $dashboard-orange-red;
        stroke-width: 1px;
    }
    &.icon-funds {
        fill: $dashboard-orange-red;
    }
    &.icon-insurance {
        fill: $dashboard-bright-green;
        stroke: none;
    }
    &.icon-payment-tokens {
        fill: $dashboard-bright-orange;
        height: 20%;
        stroke: none;
    }
    &.icon-coins {
        fill: $dashboard-bright-orange;
        stroke: $dashboard-bright-orange;
        stroke-width: 6px;
    }
    &.icon-identities {
        fill: $dashboard-dark-blue;
        transform: scale(0.87);
    }
    &.icon-arbiter {
        fill: $dashboard-dark-blue;
        stroke: none;
        }
    &.icon-signatures-and-roles{
        fill: $dashboard-orange;
        stroke: $dashboard-orange;
        stroke-width: 4px;
    }
    &.icon-help {
        fill: $dashboard-blue-green;
        stroke: none;
    }
    &.blockchain-transactions-approve {
        stroke: $dashboard-blue-green;
        stroke-width: 25px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    &.validators {
        stroke: $dashboard-blue;
        stroke-width: 8px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    &.consortium {
        stroke: $dashboard-blue;
        stroke-width: 10px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    &.nodes {
        fill: $dashboard-blue;
        stroke: $dashboard-blue;
        stroke-width: 6px;
    }
  }
}
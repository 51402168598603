.valuation-chart, .voting-chart {
    svg {
        -webkit-filter: drop-shadow( 0px 3px 3px rgba(0,0,0,.3) );
        filter: drop-shadow( 0px 3px 3px rgba(0,0,0,.25) );
    }

    /*Styling for the lines connecting the labels to the slices*/
    polyline {
        opacity: .3;
        stroke: black;
        stroke-width: 2px;
        fill: none;
    }
}

.valuation-chart .tooltip {
    position: absolute;
    display: none;
    width: auto;
    height: auto;
    background: none repeat scroll 0 0 white;
    border: 0 none;
    border-radius: 8px 8px 8px 8px;
    box-shadow: -3px 3px 15px #888888;
    padding: 5px;
    text-align: center;
}

.sliced-chart-root {
    width: 100%;
    max-height: 13em;
    @media (max-width: 500px) {
        max-height: 11em;
    }
}


#fx-daily-rates-graph {
    .area {
        fill: url(#fx-gradient);
        stroke-width: 05px;
    }

    .current-currency-pair {
        display: flex;
        justify-content: center;
        padding-bottom: 2em;
        padding-top: 2em;
        font-weight: bold;
    }

    .loading-graph-data {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .component-loader {
            padding-top: 2em;
            padding-bottom: 2em;

            &.overlay .loader {
                top: initial;
                position: relative;
            }
        }
    }

    .no-data-graph {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-bottom: 2em;
        padding-top: 2em;
    }

    .fx-options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .option {
            width: 100%;
            display: flex;
            justify-content: center;

            &.selected {
                border-bottom: 2px solid #0978e7;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .currency-pair-select {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2em;

        .currency-pair-switch {
            display: flex;
            align-items: center;
            color:#0978e7;
            padding-right: 1em;
            padding-left: 1em;

            &:hover {
                cursor: pointer;
            }
        }
        .react-select-container {
            width: 100%;
        }
    }

    .fx-scores {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .score {
            display: flex;
            flex-direction: column-reverse;

            .score-name, .score-value {
                display: flex;
                justify-content: center;
            }
        }
    }

    .fx-date-range {
        display: flex;
        justify-content: space-between;
    }
}

.helptext {
    @include breakpoint (small only) {
        font-size: 0.55rem;
    }
}

.apexcharts-tooltip {
    background: #E6E6EC;
    @include typography('body-3');
    .tooltip {
        display: flex;
        padding: $bp*3 $bp*4;
        text-align: left;
    }
    .avatar-image {
        width: 32px;
        height: 32px;
        max-width: 45px;
        max-height: 45px;
        min-width: 25px;
        min-height: 25px
    }
    .monetary-value {
        .number {
            @include typography('number-4');
        }
    }
}

.apexcharts-legend {
    align-items: flex-start;
    flex-direction: column;
}
